import { RequestParams, ContentType, Method, getHttpClient } from '@/http'
import { KycForm } from '@/model/kycObject';
import { RootObject, PageRootObject, InfoSaveRequest, Info } from '@/model/rootObject'
import { isMvgx } from './config'

function getForm() {
    return getHttpClient(true).request<RootObject<KycForm>>('/kyc/form/get', Method.GET, {}, ContentType.json)
}


function createForm(kycType: number) {
    const request = { kycType: kycType,mvgx: isMvgx(), }
    return getHttpClient(true).request<RootObject<KycForm>>('/kyc/form/create', Method.POST, request, ContentType.json)
}

function infoSave(request: InfoSaveRequest) {
    return getHttpClient(true).request<RootObject<KycForm>>('corporate/info/save', Method.POST, request, ContentType.json)
}

function getInfo() {
    return getHttpClient(true).request<RootObject<any>>('corporate/form/get', Method.GET, {}, ContentType.json)
}

function loadIndividualForm() {
    return getHttpClient(true).request<RootObject<any>>('/individual/form/get', Method.GET, {}, ContentType.json)
}


function saveIndividualData(form: any) {
    return getHttpClient(true).request<RootObject<any>>('/individual/detail/save', Method.POST, form, ContentType.json)
}

function getDataFromMyIno(code: any) {
    const request = { code: code, grantType: "authorization_code" }
    return getHttpClient(false).request<RootObject<any>>('/myInfo/personData/get', Method.POST, request, ContentType.json)
}

function getTokenForMyInfo(code: any) {
    const request = { code: code, grantType: "authorization_code" }
    return getHttpClient(false).request<RootObject<any>>('/myInfo/token/get', Method.POST, request, ContentType.json)
}

function getMyInfoUrl() {
    return getHttpClient(true).request<RootObject<any>>('/myInfo/url/get', Method.GET, {}, ContentType.json)
}

function getAdditional() {
    return getHttpClient(true).request<RootObject<any>>('/form/additional/get', Method.GET, {}, ContentType.json)
}

function saveAdditional(request: any) {
    return getHttpClient(true).request<RootObject<any>>('/form/additional/save', Method.POST, request, ContentType.json)
}

async function getDownloadUrl(fileId: any) {
    const request = { fileId: fileId }
    const url = await getHttpClient(true).getDownloadUrl('/file/download', Method.GET, request)
    return url
}
async function getDownloadNoAuth(fileId: any,type:string) {
    const request = { fileId: fileId,type :type}
    const url = await getHttpClient(true).getDownloadUrl('/file/downloadNoAuth', Method.GET, request)
    return url
}

async function downloadFile(fileId: any) {
    const request = { fileId: fileId }
    const url = await getHttpClient(true).download('/file/download', Method.GET, request)
    return url
}
async function downloadFileNoAuth(fileId: any) {
    const request = { fileId: fileId }
    const url = await getHttpClient(true).download('/file/downloadNoAuth', Method.GET, request)
    return url
}

async function downloadCloneFile(request: any) {
    // const form = { fileId: request.fileId, participantId: request. }
    const url = await getHttpClient(true).getDownloadUrl('/file/clone/download', Method.GET, request)
    return url
}
// 保存IPA
function saveIPAFile(request: any) {
    return getHttpClient(true).request<RootObject<any>>('/form-ipa-file/save', Method.POST, request, ContentType.json)
}
// 查询
function queryIPAFile(request: any) {
    return getHttpClient(true).request<RootObject<any>>('/form-ipa-file/query', Method.GET, request, ContentType.json)
}

//
function getTree() {
    return getHttpClient(true).request<RootObject<any>>('/form/non-sg/tree', Method.GET, {}, ContentType.json)
}

export default { getForm, createForm, infoSave, getInfo, loadIndividualForm, saveIndividualData, getDataFromMyIno, getTokenForMyInfo, getMyInfoUrl, getAdditional, saveAdditional, getDownloadUrl, downloadFile, downloadFileNoAuth, downloadCloneFile, saveIPAFile, queryIPAFile, getTree,getDownloadNoAuth }
