import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import './assets/style/init.less'
import './assets/style/common.less'
import './assets/style/index.less'
import { i18n } from './i18n'
import currency from '@/plus/currency'
import date from '@/plus/date'
import piniaPluginPersist from 'pinia-plugin-persist'
// import filters from '@/utils'



// 引入jQuery、bootstrap
import $ from 'jquery'
import 'bootstrap'

// 引入bootstrap样式
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

// 加密
import Config from '@/api/config'
Config.load()


const app = createApp(App)
app.use(Antd)
app.use(router)

const pinia = createPinia()
// 使用持久化插件
pinia.use(piniaPluginPersist)

app.use(pinia)
app.use(i18n)
app.use($)
app.use(currency)
app.use(date)
app.mount('#app')
