import { defineStore } from 'pinia'
import { message } from 'ant-design-vue';
import portfolioApi from '@/api/portfolio'
import currencyApi from '@/api/currency'
import { useCurrencyStore } from './currency';
import { DepositItem, CurrencyListItem, CurrencyNetworkItem, depositHistoryItem, withdrawHistoryItem, transferHistoryItem } from '@/model/depositObject'
import { CurrencyItem } from '@/model/currencyObject'
import { Balance, withdrawItem, withdrawWalletAddressItem, withdrawBankAccountItem, recentTransferListItem } from '@/model/portfolioObject'
import { getUser } from "@/api/user"
import { getPageConfig,getCdnConfig } from '@/api/config'
import { Billing } from '@/model/billingObject'
const pageConfig = getPageConfig()
const cdnConfig = getCdnConfig()

export const usePortfolioStore = defineStore('portfolio', {
    state: () => {
        return {
            overview: {
                subList: [
                    {
                        trdAccNo: '',
                        name: "Funding Portfolio",
                        fiat: "0.00",
                        btc: "0.00000000",
                        type: 2
                    },
                    {
                        trdAccNo: '',
                        name: "Crypto Portfolio",
                        fiat: "0.00",
                        btc: "0.00000000",
                        type: 3
                    },
                    {
                        trdAccNo: '',
                        name: "Investment Portfolio",
                        fiat: "0.00",
                        btc: "0.00000000",
                        type: 4
                    },
                    {
                        trdAccNo: '',
                        name: "MVGX Fiat Portfolio",
                        fiat: "0.00",
                        btc: "0.00000000",
                        type: 5
                    }
                ],
                fiat: "0.00",
                btc: "0.00000000"
            },
            selectedTab: 1,
            historyTab: 1,
            recentDepositFaitList: [{} as DepositItem],
            recentDepositCryptoList: [{} as DepositItem],
            cryptoList: [{} as CurrencyItem],
            cryptoListOcbc: [{} as CurrencyItem],
            cryptoListOcbcOpt: [{} as CurrencyItem],
            allCurrencyList: [{} as CurrencyItem],
            depositCurrencyList: [{} as CurrencyListItem],
            depositCurrencyNetworkList: [{} as CurrencyNetworkItem],
            depositCryptoForm: {
                currency: '',
                chain: '',
            },
            walletAddress: '',
            balanceList: [{} as Balance],
            historyForm: {
                currency: '',
                startDate: '',
                endDate: '',
                crypto: 0,
                pagination: pageConfig
            },
            depositHistoryList: [] as depositHistoryItem[],
            withdrawHistoryList: [] as withdrawHistoryItem[],
            transferHistoryList: [] as transferHistoryItem[],
            billingList: [] as Billing[],
            isVerificationStatus: null,
            confirmBlock: 0,
            depositCryptoCurrency: '',
            isWithdrawCryptoStatus: null,
            isWithdrawFaitStatus: null,
            recentWithdrawCryptoList: [{} as withdrawItem],
            withdrawCryptoForm: {
                amount: '' as string | number,
                currency: '',
                chain: '',
                address: '',
                network: "",
                receiveAmount: 0
            },
            // withdrawWalletAddress: [{} as withdrawWalletAddressItem],
            withdrawCurrencyList: [{} as CurrencyListItem],
            WithdrawCurrencyNetworkList: [{} as CurrencyNetworkItem],
            withdrawCancelForm: {
                ref: ''
            },
            recentWithdrawFaitList: [{} as withdrawItem],
            amount: '',
            withdrawBankAccountList: [{} as withdrawBankAccountItem],
            available: 0,
            selSource: '',
            recentTransferList: [] as depositHistoryItem[],
            active: -1,
            walletAddressList: [{} as depositHistoryItem],
            selectValue: '',
            selectValueOpt: '',
        }
    },
    getters: {
        getOverview: (state) => {
            return state.overview
        }
    },
    actions: {
        loadOverview(refresh = true) {
            const user = getUser();
            if (user) {
                const currencyStore = useCurrencyStore()
                const request = { code: currencyStore.selected }

                // 很多页面需要Portfolio 数据，但如果已经加载了就不要多次加载
                // 账号都有说明已经加载过不需要在加载
                if (refresh == false) {
                    this.overview.subList.forEach((item) => {
                        if (item.trdAccNo == "") {
                            refresh = true
                        }
                    })
                }

                if (refresh) {
                    portfolioApi.getOverview(request).then(response => {
                        if (response) {
                            if (response.code == 0) {
                                // this.transformOverview = response.data
                                this.overview = response.data
                            }
                        }
                    })
                }

            }
        },
        gotoOverview() {
            this.selectedTab = 1
        },
        gotoFunding() {
            this.loadBalanceList(2)
            this.selectedTab = 2
        },
        gotoCrypto() {
            this.loadBalanceList(3)
            this.selectedTab = 4
        },
        gotoMvgxFait() {
            this.loadBalanceList(5)
            this.selectedTab = 3
        },
        gotoInvestment() {
            this.loadBalanceList(4)
            this.selectedTab = 5
        },
        gotoDepositHistory() {
            this.historyTab = 1
        },
        gotoWithdrawHistory() {
            this.historyTab = 2
        },
        gotoTransferHistory() {
            this.historyTab = 3
        },
        loadRecentDepositList() {

            portfolioApi.getRecentDepositList({ crypto: 0 }).then(response => {
                if (response) {
                    if (response.code == 0) {
                        console.log('123123123', response.data.list)
                        this.recentDepositFaitList = response.data.list
                    }
                }
            })
        },
        getDepositHistoryCurrencyList() {
            const request = { crypto: this.historyForm.crypto }
            currencyApi.getDepositCurrencyList(request).then(response => {
                if (response) {
                    if (response.code == 0) {
                        const cryptoList = [{ id: "", currencySymbol: "ALL" } as CurrencyItem]
                        this.cryptoList = cryptoList.concat(response.data)
                        this.cryptoListOcbc = response.data.filter((item: any) => item.currencySymbol !== 'MXN')
                        this.cryptoListOcbcOpt =  response.data.filter((item: any) => item.currencySymbol == 'SGD' || item.currencySymbol == 'USD' || item.currencySymbol == 'HKD' || item.currencySymbol == 'EUR' )
                        this.selectValue = response.data[0].currencySymbol
                        this.selectValueOpt = this.cryptoListOcbcOpt[3].currencySymbol
                    }
                }
            })
        },
        getHistoryCurrencyList() {
            const request = { crypto: this.historyForm.crypto }
            currencyApi.getCurrencyList(request).then(response => {
                if (response) {
                    if (response.code == 0) {
                        const cryptoList = [{ id: "", currencySymbol: "ALL" } as CurrencyItem]
                        this.cryptoList = cryptoList.concat(response.data)

                    }
                }
            })
        },
        loadRecentDepositCryptoList() {

            portfolioApi.getRecentDepositList({ crypto: 1 }).then(response => {
                if (response) {
                    if (response.code == 0) {
                        this.recentDepositCryptoList = response.data.list
                    }
                }
            })

        },
        loadWalletAddress() {
            portfolioApi.getWalletAddress({ currency: this.depositCryptoForm.chain }).then(response => {
                if (response) {
                    if (response.code == 0) {
                        this.walletAddress = response.data.result
                    }
                }
            })
        },
        loadDepositCurrencyNetworkList() {
            portfolioApi.getDepositCurrencyNetworkList({ currency: this.depositCryptoForm.currency }).then(response => {
                if (response) {

                    if (response.code == 0) {
                        this.depositCurrencyNetworkList = response.data
                        // this.depositCryptoForm.chain = response.data[0].network
                        this.depositCurrencyNetworkList.forEach(item => {
                            if (item.network === this.depositCryptoForm.chain) {
                                this.confirmBlock = item.confirmBlock
                            }
                        })
                        console.log('this.confirmBlock', this.confirmBlock)
                    }
                }
            })
        },

        loadBalanceList(accountType: number) {
            const currencyStore = useCurrencyStore()

            const user = getUser();
            if (user && user.sub) {
                const request = {
                    currency: currencyStore.selected,
                    accountType: accountType,
                    subUserName: user.sub
                }


                portfolioApi.getBalanceList(request).then(response => {
                    if (response) {
                        if (response.code == 0) {
                            this.balanceList = response.data
                        }
                    }
                })
            }
        },
        loadDepositHistoryList() {
            portfolioApi.getDepositHistoryList(this.historyForm).then(response => {
                if (response) {
                    if (response.code == 0) {
                        this.depositHistoryList = response.data.list
                        console.log('response.pagination', response)
                        if (response.pagination) {
                            this.historyForm.pagination = response.pagination
                        }
                    }
                }
            })
        },
        loadWithdrawHistoryList() {
            portfolioApi.getWithdrawHistoryList(this.historyForm).then(response => {
                if (response) {
                    if (response.code == 0) {
                        this.withdrawHistoryList = response.data.list
                        console.log('response.paginationloadWithdrawHistoryList', response)
                        if (response.pagination) {
                            this.historyForm.pagination = response.pagination
                        }
                    }
                }
            })
        },
        loadTransferHistoryList() {
            portfolioApi.getTransferHistoryList(this.historyForm).then(response => {
                if (response) {
                    if (response.code == 0) {
                        this.transferHistoryList = response.data.list
                        if (response.pagination) {
                            this.historyForm.pagination = response.pagination
                        }
                    }
                }
            })
        },
        loadHistoryList() {
            switch (this.historyTab) {
                case 1:
                    this.loadDepositHistoryList()
                    break;
                case 2:
                    this.loadWithdrawHistoryList()
                    break;
                case 3:
                    this.loadTransferHistoryList()
                    break;
            }
        },
        loadRecentWithdrawList() {
            portfolioApi.getRecentWithdrawList({ crypto: 1 }).then(response => {
                if (response?.code == 0) {
                    this.recentWithdrawCryptoList = response.data.list
                    // var num = new Number(`${this.recentWithdrawCryptoList[1].amount}`)
                    // var num1 = num.toLocaleString()
                    // console.log(num1)
                    console.log(this.recentWithdrawCryptoList[1].amount, '-----------------------------------------------------')
                }
            })
        },
        loadRecentWithdrawFaitList() {
            portfolioApi.getRecentWithdrawList({ crypto: 0 }).then(response => {
                if (response?.code == 0) {
                    this.recentWithdrawFaitList = response.data.list
                }
            })
        },
        loadWithdrawCurrencyList() {
            portfolioApi.getDepositCurrencyList().then(response => {
                if (response) {
                    if (response.code == 0) {
                        this.withdrawCurrencyList = response.data
                        this.withdrawCryptoForm.currency = response.data[0].name
                        // this.loadWithdrawCurrencyNetworkList()
                        this.loadOverview()
                        // if (this.overview) {
                        // this.loadBalanceAvailable()
                        // }
                    }
                }
            })
        },
        loadWithdrawCurrencyNetworkList() {
            portfolioApi.getDepositCurrencyNetworkList({ currency: this.withdrawCryptoForm.currency }).then(response => {
                if (response) {
                    if (response.code == 0) {
                        this.WithdrawCurrencyNetworkList = response.data
                        console.log(' this.WithdrawCurrencyNetworkList', this.WithdrawCurrencyNetworkList)
                    }
                }
            })
        },
        loadBalanceAvailable(accountId: any) {
            console.log('accountId', accountId)
            const request = {
                currencyId: this.withdrawCryptoForm.currency,
                accountId: '',
            }
            if (accountId) {
                request.accountId = accountId
            } else {
                request.accountId = this.selSource
            }
            console.log('loadBalanceAvailable', request)
            portfolioApi.balanceAvailable(request).then(response => {
                if (response) {
                    if (response.code == 0) {
                        this.available = response.data
                    }
                }
            })
        },
        loadAllCurrencyList() {
            const request = { crypto: null }
            currencyApi.getCurrencyList(request).then(response => {
                if (response) {
                    if (response.code == 0) {
                        const cryptoList = [{ id: "", currencySymbol: "ALL" } as CurrencyItem]
                        this.allCurrencyList = cryptoList.concat(response.data)
                    }
                }
            })
        },
        loadBillingList() {
            portfolioApi.getBillingList(this.historyForm).then(response => {
                if (response) {
                    if (response.code == 0) {
                        this.billingList = response.data
                        if (response.pagination) {
                            this.historyForm.pagination = response.pagination
                        }
                    }
                }
            })
        },
        withdrawCancel() {
            const request = {
                ref: this.withdrawCancelForm.ref
            }
            portfolioApi.withdrawCancel(request).then(response => {
                if (response?.code == 0) {
                    message.success('Cancel Successful')
                    this.loadHistoryList()
                }
            })
        },
        loadRecentTransferList() {
            portfolioApi.recentTransferList().then(response => {
                if (response?.code == 0) {
                    this.recentTransferList = response.data.list
                }
            })
        },
        loadWithdrawWalletAddress() {
            portfolioApi.getWalletAddressList({ currency: this.withdrawCryptoForm.network, label: '' }).then(response => {
                if (response?.code == 0) {
                    this.walletAddressList = response.data.list
                    console.log('this.walletAddressList', this.walletAddressList)
                }
            })
        }
    },
    persist: {
        // 开启持久化
        enabled: true,
        // 选择存储方式和内容
        strategies: [
            { storage: sessionStorage, paths: ['portfolio'] }
        ]
    }
})