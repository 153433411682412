import { defineStore } from "pinia";
import { Trigger, TradingFee } from "@/model/orderObject";
import fixAPI from "@/api/fix";
import { useMarketStore } from "@/stores/market";
import { usePortfolioStore } from "@/stores/portfolio";
import { useOrderStore } from "@/stores/order";
import { useCurrencyStore } from "@/stores/currency";
import orderAPI from "@/api/order";
import { Orderbook, OrderItem } from "@/model/orderObject";
import { MarketItem } from "@/model/marketObject";
import portfolioApi from "@/api/portfolio";
import { isMvgx } from "@/api/config"
import marketAPI from '@/api/market'
import { TradingTime } from '@/model/marketObject'
const voca = require("voca");

interface OrderVO {
    price: number | null;
    amount: number | null;
    total: number | null;
    triggerPrice: number | null;
}

interface OrderContainerVO {
    fillType: number;
    type: number;
    buy: OrderVO;
    sell: OrderVO;
    validation: {
        failed: boolean;
        message: string;
        trigger: string;
    };
}

export const useTradingStore = defineStore("trading", {
    state: () => {
        return {
            symbolFilters: {
                currency: "All",
                searchText: "",
            },
            validationMessage: {
                triggerPriceRequired: "Trigger price is required",
                priceRequired: "Price is required",
                amountRequired: "Amount is required",
                minNumRequired: "Minimum quantity is",
            },
            selectedSymbolItem: {} as MarketItem,
            selectedPortfolio: 3,
            minimumAmount: 0,
            maximumAmount: 0,
            minimumAmountFast: 0,
            tradingFee: {
                makerFee: 0.15,
                takerFee: 0.25,
            } as TradingFee,
            order: {
                fillType: 1,
                type: 2, // 1 - Market, 2 - Limit, 3 - StopMarket, 4 - StopLimit, Z - MarketAtBest (不支持) 参考： fix 文档
                buy: {
                    price: null,
                    amount: null,
                    total: null,
                    triggerPrice: null,
                },
                sell: {
                    price: null,
                    amount: null,
                    total: null,
                    triggerPrice: null,
                },
                validation: {
                    failed: false,
                    message: "",
                    trigger: "",
                },
            } as OrderContainerVO,
            isSubOrderReport: false,
            fastTrade: {
                side: 1,
                selectedCrypto: "",
                cryptoList: [] as string[],
                orderTypeList: [
                    { id: 2, en: "Limit", name: "限价单" },
                    { id: 1, en: "Market", name: "市价单" },
                ] as { id: number; name: string; en: string }[],
                order: {
                    price: null,
                    amount: null,
                    total: null,
                    triggerPrice: null,
                } as OrderVO,
                priceVirtualPoint: "",
                amountVirtualPoint: "",
                totalVirtualPoint: "",
            },
            updateOrder: {
                price: null,
                amount: null,
                total: null,
                triggerPrice: null,
            } as OrderVO,
            updateOrderValidation: {
                failed: false,
                message: "",
                trigger: "",
            },
            selectedOrderItem: {} as OrderItem,
            widget: {} as any,
            selectedCurrencyBalance: 0,
            selectedCryptoBalance: 0,
            t: {} as any,
            tradingTime: {} as TradingTime,
        };
    },
    getters: {
        selectedCurrency: (state) => {
            if (voca.isBlank(state.selectedSymbolItem.symbol)) {
                return "";
            } else {
                return state.selectedSymbolItem.symbol.split("/")[1];
            }
        },
        selectedCrypto: (state) => {
            if (voca.isBlank(state.selectedSymbolItem.symbol)) {
                return "";
            } else {
                return state.selectedSymbolItem.symbol.split("/")[0];
            }
        },
        timeInForce: (state) => {
            /**
                   * 0 - Day
                      1 - Good till cancelled
                      3 - Immediate or Cancel (IOC)
                      4 - Fill or Kill (FoK)
                      6 - Good till date (GTD)
                      S - Session
                   * 参考： fix 协议文档
                   * 
                   */
            let timeInForce = "0";
            if (state.order.fillType == 1) {
                timeInForce = "1";
            } else if (state.order.fillType == 2) {
                timeInForce = "3";
            } else if (state.order.fillType == 3) {
                timeInForce = "4";
            }
            return timeInForce;
        },
        fastTaderFee: (state) => {
            if (state.fastTrade.side == 1) {
                let result =
                    (Number(state.fastTrade.order.amount) * state.tradingFee.takerFee) /
                    100;
                result = Number(result.toFixed(2));
                return result;
            } else {
                let result =
                    (Number(state.fastTrade.order.total) * state.tradingFee.takerFee) /
                    100;
                result = Number(result.toFixed(2));
                return result;
            }
        },
        updateOrderBuyTaderFee: (state) => {
            let result =
                (Number(state.updateOrder.amount) * state.tradingFee.takerFee) / 100;
            result = Number(result.toFixed(2));
            return result;
        },
        updateOrderSellTaderFee: (state) => {
            let result =
                (Number(state.updateOrder.total) * state.tradingFee.takerFee) / 100;
            result = Number(result.toFixed(2));
            return result;
        },
        selectedFastTradeSymbol: (state) => {
            const currencyStore = useCurrencyStore();
            return state.fastTrade.selectedCrypto + "/" + currencyStore.selected;
        },
    },
    actions: {

        makeOrder(order: OrderVO, side: string, symbol: string, accountType = 0) {
            if (order.amount) {

                // if (this.selectedCrypto == "BTC" && order.amount < 0.0005) {
                //     this.order.validation.failed = true;
                //     this.order.validation.message = "The Minimum Trading Quantity for BTC is 0.0005.";
                //     return
                // }

                // if (this.selectedCrypto == "ETH" && order.amount < 0.005) {
                //     this.order.validation.failed = true;
                //     this.order.validation.message = "The Minimum Trading Quantity for ETH is 0.005.";
                //     return
                // }
                console.log(order.amount, this.minimumAmount,this.maximumAmount, 'min小小')
                if (order.amount < this.minimumAmount) {
                    console.log('yes min or')
                    this.order.validation.failed = true;
                    this.order.validation.message = `Minimum quantity is ${this.minimumAmount}`;
                    return
                }
                if (order.amount > this.maximumAmount) {
                    this.order.validation.failed = true;
                    this.order.validation.message = `Maximum quantity is ${this.maximumAmount}`;
                    return
                }
                /**
                 * stop order handler =====> 3 - StopMarket, 4 - StopLimit
                 *
                 * - TriggerType: Defines when the trigger will hit, i.e. the action specified by the trigger instructions will come into effect.
                 * 1 - Partial Execution, 4 - Price Movement - 4 is the default value.
                 *
                 * - TriggerPriceType The type of price that the trigger is compared.
                 * 1 - Best Offer, 2 - Last Trade, 3 - Best Bid 2 is the default value.
                 *
                 * - TriggerPriceDirection
                 * U - Trigger if the price of the specified type goes UP to or through the specified Trigger Price
                 * D - Trigger if the price of the specified type goes DOWN to or through the specified Trigger Price
                 */
                const trigger = {
                    triggerType: null,
                    triggerPrice: null,
                    triggerPriceType: null,
                    triggerPriceDirection: null,
                } as Trigger;

                if (this.order.type == 3 || this.order.type == 4) {
                    let price = 0;
                    if (order.price) {
                        price = order.price;
                    }

                    const marketStore = useMarketStore();
                    // stop market price
                    if (this.order.type == 4) {
                        if (side == "1") {
                            price = marketStore.bestPrice.buy;
                        } else {
                            price = marketStore.bestPrice.sell;
                        }
                    }

                    if (order.triggerPrice) {
                        trigger.triggerType = "4";
                        trigger.triggerPrice = order.triggerPrice;
                        trigger.triggerPriceType = "2";
                        trigger.triggerPriceDirection =
                            order.triggerPrice > price ? "U" : "D";
                    } else {
                        console.log("trigger porice is required");
                        return;
                    }
                }



                if (accountType == 0) {
                    accountType = this.selectedSymbolItem.accountType;
                }

                const portfolioStore = usePortfolioStore();
                let account = portfolioStore.overview.subList[0].trdAccNo;
                if (side == "1") {
                    if (accountType == 3) {
                        // MCE Fiat Portfolio
                        account = portfolioStore.overview.subList[0].trdAccNo;
                    } else {
                        // MCE Fiat Portfolio
                        account = portfolioStore.overview.subList[2].trdAccNo;
                    }
                } else {
                    if (accountType == 3) {
                        account = portfolioStore.overview.subList[1].trdAccNo;
                    } else {
                        account = portfolioStore.overview.subList[3].trdAccNo;
                    }
                }

                const orderData = {
                    instrument: {
                        symbol: symbol,
                    },
                    account: account, // to do 临时， 买卖账号
                    orderQty: order.amount,
                    ordType: this.order.type, // ordType == 2 是limit order，  ui 上1, 3 是 limit
                    price: order.price,
                    side: side, // 1 买， 2 卖
                    timeInForce: this.timeInForce,
                    trigger: trigger,
                    senderSubId: account, // to do 临时， 下单用户
                };
                if (orderData) {
                    fixAPI.makeOrder(orderData).then((res) => {
                        if (res) {
                            if (res.code != 0) {
                                this.order.validation.failed = true;
                                this.order.validation.message = res.code.toString();
                            }
                        }
                    });
                }
            }
        },
        clearOrder(orderVo: OrderVO) {
            orderVo.amount = null;
            orderVo.price = null;
            orderVo.total = null;
            orderVo.triggerPrice = null;

            // limit 订单清楚filltype 选项
            if (this.order.type == 2 || this.order.type == 4) {
                this.order.fillType = 1;
            }
        },
        //交易对最小值
        getOrderBookSetting() {
            orderAPI.getOrderBookSetting(this.selectedSymbolItem.symbol).then(response => {
                if (response) {
                    if (response.code == 0) {
                        console.log('minnum000')
                        this.minimumAmount = response.data.minimumAmount
                        this.maximumAmount = response.data.maximumAmount
                    }
                }
            })
        },
        getOrderBookSettingFast(value: any) {
            orderAPI.getOrderBookSetting(value).then(response => {
                if (response) {
                    if (response.code == 0) {

                        this.minimumAmountFast = response.data.minimumAmount
                        this.maximumAmount = response.data.maximumAmount
                        console.log('fastminnum000', this.minimumAmountFast)
                    }
                }
            })
        },
        buy() {
            if (!this.validateInput(this.order.buy, "buy")) {
                return;
            }
            this.makeOrder(this.order.buy, "1", this.selectedSymbolItem.symbol);
            // 清空已成功订单
            this.clearOrder(this.order.buy);
        },
        sell() {
            if (!this.validateInput(this.order.sell, "sell")) {
                return;
            }
            this.makeOrder(this.order.sell, "2", this.selectedSymbolItem.symbol);
            // 清空已成功订单
            this.clearOrder(this.order.sell);
        },

        subOrderReport(code: string) {
            if (this.isSubOrderReport == false) {
                this.isSubOrderReport = true;
                fixAPI.subOrderReport(code, (data: any) => {
                    const orderStore = useOrderStore();
                    // 错误信息处理
                    if (data.response.text) {
                        let code = data.response.text.split("):")[0];
                        code = code.replace("(", "");
                        console.log(code);
                        let text = data.response.text.split("):")[1];

                        // Update Order 时候的错误信息处理
                        const message = this.t("me.messages." + code);
                        if (message.indexOf("me.messages.") == -1) {
                            text = message;
                        }


                        if (orderStore.selectOrdedrItem.id) {
                            this.updateOrderValidation.failed = true;
                            this.updateOrderValidation.message = text;
                            this.updateOrderValidation.trigger = "report";
                        } else {
                            this.order.validation.failed = true;
                            this.order.validation.message = text;
                            this.order.validation.trigger = "report";
                        }
                    }

                    // 刷新列表
                    setTimeout(() => {
                        orderStore.loadOrderListForTrade(false);
                        orderStore.loadOrderListForTrade(true);
                    }, 1000);

                    // 刷新持仓
                    // this.loadBalance();

                });
            }
        },
        subBalanceChange(code: string) {
            const participantCode = code.substring(0, 8);
            portfolioApi.subBlanceChange(participantCode, (data) => {

                const currencyStore = useCurrencyStore();
                let cryptoAccountType = currencyStore.currencyAccountTypeMap.get(this.selectedSymbolItem.baseCurrencyId);
                let currencyAccountType = 0;
                if (cryptoAccountType == 4) {
                    currencyAccountType = 5;
                }

                if (cryptoAccountType == 3) {
                    currencyAccountType = 2;
                }

                const portfolioStore = usePortfolioStore();
                for (let i = 0; i < portfolioStore.overview.subList.length; i++) {
                    if (portfolioStore.overview.subList[i].trdAccNo == data.response.account) {

                        if (portfolioStore.overview.subList[i].type == currencyAccountType
                            && this.selectedSymbolItem.quoteCurrencyId == data.response.currencyId) {
                            this.selectedCurrencyBalance = data.response.availableBalance;
                            break;
                        }

                        if (portfolioStore.overview.subList[i].type == cryptoAccountType
                            && this.selectedSymbolItem.baseCurrencyId == data.response.currencyId) {
                            this.selectedCryptoBalance = data.response.availableBalance;
                            break;
                        }
                    }
                }






            });
        },
        clearMessage() {
            this.order.validation.failed = false;
            this.order.validation.message = "";
            this.order.validation.trigger = "";
        },
        validateInput(order: OrderVO, side: string) {
            if (
                voca.isBlank(order.triggerPrice) &&
                (this.order.type == 3 || this.order.type == 4)
            ) {
                this.order.validation.failed = true;
                this.order.validation.message =
                    this.validationMessage.triggerPriceRequired;
                this.order.validation.trigger = side + ".triggerPrice";
                return false;
            }

            if (
                voca.isBlank(order.price) &&
                (this.order.type == 2 || this.order.type == 4)
            ) {
                this.order.validation.failed = true;
                this.order.validation.message = this.validationMessage.priceRequired;
                this.order.validation.trigger = side + ".price";
                return false;
            }
            if (voca.isBlank(order.amount)) {
                this.order.validation.failed = true;
                this.order.validation.message = this.validationMessage.amountRequired;
                this.order.validation.trigger = side + ".amount";
                return false;
            }

            this.order.validation.message = "";
            return true;
        },
        fastTradeValidateInput(order: any) {
            console.log('fastmimimimi', order.amount, this.minimumAmountFast)
            if (voca.isBlank(order.amount)) {
                this.order.validation.failed = true;
                this.order.validation.message = this.validationMessage.amountRequired;
                this.order.validation.trigger = "amount";
                return false;
            }
            if (
                voca.isBlank(order.price) &&
                (this.order.type == 2 || this.order.type == 4)
            ) {
                this.order.validation.failed = true;
                this.order.validation.message = this.validationMessage.priceRequired;
                this.order.validation.trigger = "price";
                return false;
            }
            if (order.amount < this.minimumAmountFast) {
                console.log('fast yes min or')
                this.order.validation.failed = true;
                this.order.validation.message = this.validationMessage.minNumRequired;
                return
            }
            this.order.validation.message = "";
            return true;
        },
        updateOrderValidateInput(order: OrderVO) {
            if (voca.isBlank(order.price)) {
                this.updateOrderValidation.failed = true;
                this.updateOrderValidation.message =
                    this.validationMessage.priceRequired;
                this.updateOrderValidation.trigger = "price";
                return false;
            }
            if (voca.isBlank(order.amount)) {
                this.updateOrderValidation.failed = true;
                this.updateOrderValidation.message =
                    this.validationMessage.amountRequired;
                this.updateOrderValidation.trigger = "amount";
                return false;
            }
            this.order.validation.message = "";
            return true;
        },

        selectBuyOrder(item: any) {
            // limit order 设置 price
            if (this.order.type == 2 || this.order.type == 4) {
                this.order.sell.price = item.price;
            }
            this.order.sell.amount = item.quantity;
            this.order.sell.total = item.total;
        },
        selectSellOrder(item: any) {
            // limit order 设置 price
            if (this.order.type == 2 || this.order.type == 4) {
                this.order.buy.price = item.price;
            }
            this.order.buy.amount = item.quantity;
            this.order.buy.total = item.total;
        },
        loadCryptoList() {
            const marketStore = useMarketStore();
            if (marketStore.marketItemList[0]) {
                this.fastTrade.selectedCrypto = marketStore.marketItemList[0].baseCurrency;
            }
        },
        submitOrder() {
            const currencyStore = useCurrencyStore();

            this.order.fillType = 0;
            // market order
            if (this.order.type == 1) {
                this.order.fillType = 2;
                this.order.buy.price = null;
            }
            // limit order
            if (this.order.type == 2) {
                this.order.fillType = 0;
            }

            const symbol =
                this.fastTrade.selectedCrypto + "/" + currencyStore.selected;

            console.log("this.fastTrade.selectedCrypto", this.fastTrade.selectedCrypto);

            const accountType = currencyStore.currencyAccountTypeMap.get(this.fastTrade.selectedCrypto)
            this.makeOrder(this.fastTrade.order, String(this.fastTrade.side), symbol, accountType);
            this.clearOrder(this.fastTrade.order);
        },
        updateFastOrder() {
            const request = {
                orderID: this.selectedOrderItem.id,
                price: this.selectedOrderItem.price,
                orderQty: this.selectedOrderItem.amount,
            };
        },
        loadTradingFee() {
            const currencyStore = useCurrencyStore();
            const secCode =
                this.fastTrade.selectedCrypto + "/" + currencyStore.selected;
            const portfolioStore = usePortfolioStore();
            let accountId = portfolioStore.overview.subList[1].trdAccNo;
            orderAPI.getTradingFee(secCode, accountId).then((response) => {
                if (response) {
                    this.tradingFee = response.data;
                    console.log("load trading fee ...");
                }
            });
        },
        loadBalance() {
            const currencyStore = useCurrencyStore();
            let cryptoAccountType = currencyStore.currencyAccountTypeMap.get(this.selectedSymbolItem.baseCurrencyId);
            let currencyAccountType = 0;
            if (cryptoAccountType == 4) {
                currencyAccountType = 5;
            }

            if (cryptoAccountType == 3) {
                currencyAccountType = 2;
            }

            // delay 500 毫秒在加载 余额
            setTimeout(() => {
                portfolioApi.getBalanceAvailable(this.selectedCurrency, currencyAccountType).then((response) => {
                    if (response) {
                        this.selectedCurrencyBalance = response.data;
                    }
                })

                if (cryptoAccountType) {
                    portfolioApi.getBalanceAvailable(this.selectedSymbolItem.baseCurrencyId, cryptoAccountType).then((response) => {
                        if (response) {
                            this.selectedCryptoBalance = response.data;
                        }
                    })
                }

            }, 1000)
        },
        /**
         * 获取开盘时间和收盘时间
         */
        getTradingTime() {
            marketAPI.getTradingTime().then(response => {
                if (response && response.code == 0 && response.data) {
                    this.tradingTime = response.data
                }
                console.log(response)
            })
        }
    },
});
