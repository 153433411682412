const key: string = "trader-site-token-key"

function getToken(): string | null {
    const token = sessionStorage.getItem(key)
    return token
}

function setToken(token: string) {
    sessionStorage.setItem(key, token)
}

function removeToken() {
    sessionStorage.removeItem(key)
}

export { getToken, setToken, removeToken }