import { RequestParams, ContentType, Method, getHttpClient } from '@/http'
import { CurrencyRequest, CurrencyItem,CurrencyOtcItem } from '@/model/currencyObject'
import { RootObject } from '@/model/rootObject'
function getSymbol(currency: string) {
    if (currency == "USD") {
        return "$"
    }

    if (currency == "EUR") {
        return "€"
    }

    if (currency == "SGD") {
        return "S$"
    }

    return "$";
}

function getCurrencyList(request: CurrencyRequest) {
    return getHttpClient(true).request<RootObject<CurrencyItem[]>>('/currency/setting/list-by-crypto', Method.POST, request, ContentType.json)
}
function getDepositCurrencyList(request: CurrencyRequest) {
    return getHttpClient(true).request<RootObject<CurrencyItem[]>>('/currency/setting/list/all-by-crypto', Method.POST, request, ContentType.json)
}
function getOtcCurrencyList() {
    return getHttpClient(true).request<RootObject<CurrencyOtcItem>>('/otc/currency/setting/list', Method.GET)
}

//千分符
function ThousandDivide(number:any) {
    let isNegative = number < 0;
    if (isNegative) {
        number = - number;
    }
    let numberArr = number.toString().split('.');
    let resultNum = null;
    let integer = numberArr[0];
    let decimal = '';
    let integerArr = [];
    //  存在小数位
     if (numberArr.length > 1 ) {
        decimal = `.${numberArr[1]}`;   
     }
     
    let divideNum = integer.length/3;
 
    for (let i=0; i<divideNum; i++) {
        integerArr.unshift(integer.slice(-3));
        integer = integer.slice(0, -3);
 
    }
    integer = integerArr.join(',');
    resultNum = isNegative ? `-${integer+decimal}`: integer+decimal;  
    return resultNum;
}

export default { getSymbol, getCurrencyList,ThousandDivide,getDepositCurrencyList,getOtcCurrencyList }