import ReconnectingWebSocket from 'reconnecting-websocket';

import { getWebSocketConfig } from '@/api/config'


const config = getWebSocketConfig()

console.log("load wss url", config.url)

const rws = new ReconnectingWebSocket(config.url, [], {});

/* 订阅列表 */
const subscriptionMap = new Map();

rws.addEventListener('open', () => {
    // 断点续传，重新订阅消息
    for (let subscription of subscriptionMap.values()) {
        const json = JSON.stringify(subscription)
        rws.send(subscription);
    }
});


rws.addEventListener('message', (res) => {
    const json = res.data;
    const data = JSON.parse(json);

    if (data) {
        // ping pong 保持连接
        if (isPing(data)) {
            keepActive(data);
        } else {
            // 触发回调函数
            const message = subscriptionMap.get(data.sub);
            if (message) {
                message.callback(data);
            }
        }
    }

});

function isPing(data: any): boolean {
    return data.ping;
}


interface pingMessage {
    ping: number;
}

class pongMessage {
    pong: number;
    // 构造函数 
    constructor(id: number) {
        this.pong = id
    }
    toJson(): string {
        return JSON.stringify(this)
    }
}


/* pong 保存ws连接处理 */
function keepActive(message: pingMessage) {
    if (message.ping) {
        let pong = new pongMessage(message.ping);
        const json = pong.toJson();
        rws.send(json);
    }
}

interface subscribeMessage {
    sub: string;
    applicationID: string;
    callback: (message: any) => void
}

function subscribe(sub: string, data: any, callback: (data: any) => void) {

    let message: subscribeMessage = {
        sub: sub,
        applicationID: config.applicationId,
        callback: callback
    }

    message = Object.assign(
        message,
        data
    )


    // 保存订阅信息到Map， 断点重连时订阅
    subscriptionMap.set(message.sub, message);
    const json = JSON.stringify(message);
    // 发送订阅消息
    rws.send(json);
    console.log(rws.readyState)
}

function unsubscribe(sub: string, data: any) {
    let unmessage = {
        unsub: sub,
        applicationID: config.applicationId
    }

    if (data) {
        unmessage = Object.assign(
            unmessage,
            data
        )
    }

    subscriptionMap.delete(sub);

    const json = JSON.stringify(unmessage)
    // 取消订阅消息
    rws.send(json);
}

export default { subscribe, unsubscribe }