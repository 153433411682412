import { ContentType, Method, getHttpClient } from '@/http'
import { RootObject, PageRootObject } from '@/model/rootObject'
import { Orderbook, SerachOrderListRequest, OrderItem, SerachTradeListRequest, TradeItem, SerachInvestmentListRequest, InvestmentOrderItem, TradingFee,OrderBookSetting } from '@/model/orderObject'


function getOrderbookList() {
    return getHttpClient(true).request<RootObject<Orderbook[]>>('orderbook/list', Method.GET, {}, ContentType.json)
}

function getOrderList(request: SerachOrderListRequest) {
    const paginationConfig = {
        headers: {
            pageSize: request.pagination.pageSize,
            pageNumber: request.pagination.pageNumber,
            sortBy: 'time:descending'
        }
    }

    return getHttpClient(true).request<PageRootObject<OrderItem[]>>('order/search-by-account', Method.POST, request, ContentType.json, paginationConfig)
}


function getTradeList(request: SerachTradeListRequest) {
    const paginationConfig = {
        headers: {
            pageSize: request.pagination.pageSize,
            pageNumber: request.pagination.pageNumber
        }
    }

    return getHttpClient(true).request<PageRootObject<TradeItem[]>>('trade/search-by-account', Method.POST, request, ContentType.json, paginationConfig)
}


function getInvestmentList(request: SerachInvestmentListRequest) {
    const paginationConfig = {
        headers: {
            pageSize: request.pagination.pageSize,
            pageNumber: request.pagination.pageNumber
        }
    }

    return getHttpClient(true).request<PageRootObject<InvestmentOrderItem[]>>('mini-btc/record/search-by-account', Method.POST, request, ContentType.json, paginationConfig)
}


function getTradingFee(secCode: string, accountId: string) {
    return getHttpClient(true).request<RootObject<TradingFee>>('trading-fee/get', Method.POST, { secCode, accountId }, ContentType.json)
}

function getOrderBookSetting(secCode: string) {
    return getHttpClient(true).request<RootObject<OrderBookSetting>>('order_book_setting/get', Method.POST, { secCode }, ContentType.json)
}

export default { getOrderbookList, getOrderList, getTradeList, getInvestmentList, getTradingFee ,getOrderBookSetting}