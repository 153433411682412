import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from 'vue-router'
import HomePage from '@/views/home/MCENew.vue'
import { getToken } from "@/http/auth"
import { Role, getUser, hasRole } from "@/api/user"


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  // 新版
  {
    path: '/mce',
    name: 'MCE',
    component: () => import('../views/home/MCENew.vue')
  },
  // 旧版
  // {
  //   path: '/mce',
  //   name: 'MCE',
  //   component: () => import('../views/home/MCEIndex.vue')
  // },
  {
    path: '/our-vision',
    name: 'OurVision',
    component: () => import('../views/home/OurVisionIndex.vue')
  },
  {
    path: '/products-services',
    name: 'ProductsServices',
    component: () => import('../views/home/ProductIndex.vue')
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import('../views/portfolio/index.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/profile/index.vue')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('../views/portfolio/history/index.vue')
  },
  {
    path: '/markets',
    name: 'markets',
    component: () => import('../views/markets/index.vue')
    // beforeEnter:(to,form,next)=>{
    //   if (to.name == "markets") {
    //     if (hasAction(Role.markets)) {
    //       next();
    //     }else{
    //       router.push('/login')
    //     }
    //   }
    // }
  },
  {
    path: '/spot-trading',
    name: 'SpotTrading',
    component: () => import('../views/trades/spotTrading/index.vue')
  },
  {
    path: '/securities',
    name: 'Securities',
    component: () => import('../views/trades/spotTrading/index.vue')
  },
  {
    path: '/fast-trade',
    name: 'FastTrade',
    component: () => import('../views/trades/fastTrade/index.vue')
  },
  {
    path: '/nft-overview',
    name: 'NftOverview',
    component: () => import('@/views/nft/Overview.vue')
  },
  {
    path: '/nft-collections',
    name: 'NftCollections',
    component: () => import('@/views/nft/collections/index.vue')
  },
  {
    path: '/otc',
    name: 'otc',
    component: () => import('../views/otc/index.vue')
  },
  {
    path: '/disclosure',
    name: 'Disclosure',
    component: () => import('../views/company/disclosure/index.vue')
  },
  {
    path: '/terms',
    name: 'termsAndConditions',
    component: () => import('../views/company/termsAndConditions/index.vue')
  },
  // {
  //   path: '/privacyPolicy',
  //   name: 'privacyPolicy',
  //   component: () => import('../views/company/privacyPolicy/index.vue')
  // },
  {
    path: '/careers',
    name: 'Careers',
    component: () => import('../views/company/careers/index.vue')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../views/faq/index.vue')
  },
  {
    path: '/faq-mvgx',
    name: 'FAQMvgx',
    component: () => import('../views/faq/indexMvgx.vue')
  },
  {
    path: '/mini-bitcoin',
    name: 'MiniBitcoin',
    component: () => import('../views/Investments/miniBitcoin/index.vue')
  },
  {
    path: '/mini-agreement',
    name: 'miniAgreement',
    component: () => import('../views/Investments/miniBitcoin/components/Agreement.vue')
  },
  {
    path: '/hash-rate',
    name: 'HashRate',
    component: () => import('../views/Investments/hashRate/index.vue')
  },
  {
    path: '/cnvis',
    name: 'Cnvis',
    component: () => import('../views/Investments/cnvis/index.vue')
  },
  {
    path: '/bitcoin-index',
    name: 'BitcoinIndex',
    component: () => import('../views/Investments/bitcoinIndex/index.vue')
  },
  {
    path: '/meth',
    name: 'Meth',
    component: () => import('../views/Investments/meth/index.vue')
  },
  {
    path: '/mmn',
    name: 'mmn',
    component: () => import('../views/Investments/mmn/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/LogIn.vue')
  },
  {
    path: '/sign-up',
    name: 'signUp',
    component: () => import('../views/login/SignUp.vue')
  },
  {
    path: '/sign-up-information',
    name: 'SignUpInformation',
    component: () => import('../views/login/SignUpInformation.vue')
  },

  {
    path: '/twoFa',
    name: 'TwoFa',
    component: () => import('../views/login/TwoFa.vue')
  },
  {
    path: '/forgot-code',
    name: 'ForgotCode',
    component: () => import('../views/login/ForgotCode.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () => import('../views/login/ForgotPassword.vue')
  },
  {
    path: '/two-fa',
    name: 'twoFa',
    component: () => import('../views/login/TwoFa.vue')
  },
  {
    path: '/fee-mobile',
    name: 'feeMobile',
    component: () => import('../views/fee/indexMobile.vue')
  },
  {
    path: '/fee',
    name: 'fee',
    component: () => import('../views/fee/index.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/agreement/index.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/Test.vue')
  },
  {
    path: '/portfolio-deposit-fiat',
    name: 'portfolioDepositFiat',
    component: () => import('@/views/portfolio/deposit/DepositFiat.vue')
  },
  {
    path: '/portfolio-deposit-mvgx-fiat',
    name: 'portfolioDepositMvgxFiat',
    component: () => import('@/views/portfolio/deposit/DepositMvgxFiat.vue')
  },
  {
    path: '/portfolio-deposit-crypto',
    name: 'portfolioDepositCrypto',
    component: () => import('@/views/portfolio/deposit/DepositCrypto.vue')
  },
  {
    path: '/withdraw-fiat',
    name: 'WithdrawFiat',
    component: () => import('@/views/portfolio/withdrawal/WithdrawFiat.vue')
  },
  {
    path: '/withdraw-mvgx-fiat',
    name: 'WithdrawMvgxFiat',
    component: () => import('@/views/portfolio/withdrawal/WithdrawMvgxFiat.vue')
  },
  {
    path: '/withdraw-crypto',
    name: 'WithdrawCrypto',
    component: () => import('@/views/portfolio/withdrawal/WithdrawCrypto.vue')
  },
  {
    path: '/bank-account',
    name: 'bankAccount',
    component: () => import('@/views/portfolio/withdrawal/BankAccount.vue')
  },
  {
    path: '/wallet-address',
    name: 'WalletAddress',
    component: () => import('@/views/portfolio/withdrawal/WalletAddress.vue')
  },
  {
    path:'/add-wallet-address',
    name:"AddWalletAddress",
    component: () => import('@/views/portfolio/withdrawal/AddWalletAddress.vue')
  },
  {
    path:'/add-wallet-page',
    name:"AddWalletPage",
    component: () => import('@/views/portfolio/withdrawal/AddWalletPage.vue')
  },
  {
    path: '/transter',
    name: 'transter',
    component: () => import('@/views/portfolio/transter/Transter.vue')
  },
  {
    path: '/movement',
    name: 'Movement',
    component: () => import('@/views/portfolio/movement/index.vue')
  },
  {
    path: '/statement',
    name: 'Statement',
    component: () => import('@/views/portfolio/statement/index.vue')
  },
  {
    path: '/statement-export',
    name: 'StatementExport',
    component: () => import('@/views/portfolio/statement/export.vue')
  },
  {
    path: '/fund-password',//资金密码
    name: 'fundPassword',
    component: () => import('@/views/profile/security/FundPassword.vue')
  },
  {
    path: '/google-verify',
    name: 'googleVerify',
    component: () => import('@/views/profile/security/GoogleVerify.vue')
  },
  {
    path: '/sub-account-assets',
    name: 'SubAccountAssets',
    component: () => import('@/views/profile/subAccounts/subAccountAssets/index.vue')
  },
  {
    path: '/nft-new-item',
    name: 'NftNewItem',
    component: () => import('@/views/nft/components/NftNewItem.vue')
  },
  {
    path: '/nft-sell',
    name: 'NftSell',
    component: () => import('@/views/nft/components/Sell.vue')
  },
  {
    path: '/nft-edit',
    name: 'NftEdit',
    component: () => import('@/views/nft/components/NftEdit.vue')
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('@/views/orders/index.vue')
  },
  {
    path: '/default', //首次登录显示页面
    name: 'default',
    component: () => import('@/views/home/default.vue')
  },
  {
    path: '/login-Password',
    name: 'loginPassword',
    component: () => import('@/views/profile/security/LoginPassword.vue')
  },
  {
    path: '/per-docs-required',
    name: 'PerDocsRequired',
    component: () => import('@/views/profile/kycVerification/individual/DocsRequired.vue')
  },
  {
    path: '/cor-docs-required',
    name: 'CorDocsRequired',
    component: () => import('@/views/profile/kycVerification/enterprise/DocsRequired.vue')
  },
  {
    path: '/market-trade-rule',
    name: 'MarketTradeRule',
    component: () => import('@/components/MarketTradeRule.vue')
  },
  {
    path: '/market-trade-rule-mvgx',
    name: 'MarketTradeRuleMvgx',
    component: () => import('@/components/MarketTradeRuleMvgx.vue')
  },
  {
    path: '/market-trade-rule-view',
    name: 'MarketTradeRuleView',
    component: () => import('@/components/MarketTradeRuleView.vue')
  },
  // individual 个人表单
  {
    path: '/individual-verification',
    name: 'individual Verification',
    component: () => import('@/views/profile/kycVerification/individual-verification/index.vue')
  },
  // 企业
  {
    path: '/enterprise-verification',
    name: 'enterprise Verification',
    component: () => import('@/views/profile/kycVerification/enterprise-verification/index.vue')
  },

  // call bcak
  {
    path: '/callback',
    name: 'KycCallback',
    component: () => import('@/views/profile/kycVerification/callback.vue')
  },
  // call bcak
  {
    path: '/myinfocallback',
    name: 'DeployKycCallback',
    component: () => import('@/views/profile/kycVerification/callback.vue')
  },
  //kyc iframe 个人表单
  {
    path: '/individual-form',
    name: 'IndividualForm',
    component: () => import('@/views/profile/kycVerification/individual-verification/IndividualForm.vue')
  },
  //kyc iframe 个人表单
  {
    path: '/individual-form-view',
    name: 'IndividualFormView',
    component: () => import('@/views/profile/kycVerification/individual-verification/IndividualFormView.vue')
  },
  // individual iframe 个人表单 3级
  {
    path: '/individual-phase-form',
    name: 'Individual phase',
    component: () => import('@/views/profile/kycVerification/individual-phase3/IndividualPhaseForm.vue')
  },
  //kyc iframe 企业表单
  {
    path: '/enterprise-form',
    name: 'EnterpriseForm',
    component: () => import('@/views/profile/kycVerification/enterprise-verification/EnterpriseForm.vue')
  },
  //kyc iframe 企业表单
  {
    path: '/enterprise-form-view',
    name: 'EnterpriseFormView',
    component: () => import('@/views/profile/kycVerification/enterprise-verification/EnterpriseFormView.vue')
  },
  //kyc iframe 企业表单 3级
  {
    path: '/enterprise-phase-form',
    name: 'Enterprise phase',
    component: () => import('@/views/profile/kycVerification/enterprise-phase3/enterprisePhaseForm.vue')
  },
  // 模拟登录
  {
    path: '/simulate-login',
    name: 'SimulateLogin',
    component: () => import('@/views/login/SimulateLogin.vue')
  },
  // kyc-status
  {
    path: '/kyc-status',
    name: 'KycStatus',
    component: () => import('@/views/profile/kycVerification/kyc-status/index.vue')
  },
  // kyc-individual-phase3
  {
    path: '/kyc-individual-phase3',
    name: 'KycIndividualPhase3',
    component: () => import('@/views/profile/kycVerification/individual-phase3/index.vue')
  },
  // kyc-enterpirse-phase3
  {
    path: '/kyc-enterprise-phase3',
    name: 'KycEnterprisePhase3',
    component: () => import('@/views/profile/kycVerification/enterprise-phase3/index.vue')
  },
  // kyc-admin
  {
    path: '/kycVerification',
    name: 'kycVerification',
    component: () => import('@/views/profile/components/KycVerification.vue')
  },
  // kyc-admin
  {
    path: '/individual',
    name: 'Individual',
    component: () => import('@/views/profile/kycVerification/individual-verification/Individual.vue')
  },
  {
    path: '/enterprise',
    name: 'enterpriseVerification',
    component: () => import('@/views/profile/kycVerification/enterprise-verification/Enterprise.vue')
  },
  //kyc add quest
  {
    path: '/add-quest',
    name: 'AddQuest',
    component: () => import('@/views/profile/kycVerification/components/AddQuest.vue')
  },
  //fee schedule
  {
    path: '/fee-schedule',
    name: 'FeeSchedule',
    component: () => import('@/components/FeeSchedule.vue')
  },
  //
  {
    path: '/fee-schedule',
    name: 'FeeSchedule',
    component: () => import('@/components/FeeSchedule.vue')
  },
  {
    path: '/page-maintenance',
    name: 'PageMaintenance',
    component: () => import('@/components/PageMaintenance.vue')
  },
  {
    path: '/expect',
    name: 'Expect',
    component: () => import('@/components/Expect.vue')
  },
  {
    path: '/investments',
    name: 'Investments',
    component: () => import('../views/Investments/pages/index.vue')
  },
  {
    path: '/investments-agreement',
    name: 'investmentsAgreement',
    component: () => import('../views/Investments/components/Agreement.vue')
  },
  {
    path: '/mail-notification-settings',
    name: 'MailNotificationSettings',
    component: () => import('@/views/profile/security/MailNotificationSettings.vue')
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import('@/views/home/AboutUs.vue')
  },
  {
    path: '/articles',
    name: 'Articles',
    component: () => import('@/views/articles/Articles.vue')
  },
  {
    path: '/PYUSD-listing',
    name: 'ArticlesDetail',
    component: () => import('@/views/articles/ArticlesDetail.vue')
  },
  {
    path: '/PYUSD-listing-two',
    name: 'ArticlesDetailTwo',
    component: () => import('@/views/articles/ArticlesDetailTwo.vue')
  },
  {
    path: '/PYUSD-listing-three',
    name: 'ArticlesDetailThree',
    component: () => import('@/views/articles/ArticlesDetailThree.vue')
  },
  {
    path: '/termsofuse',
    name: 'TermsOfUse',
    component: () => import('@/views/legal/termsofuse/index.vue')
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: () => import('@/views/legal/privacypolicy/index.vue')
  },
  {
    path: '/cookiespolicy',
    name: 'CookiesPolicy',
    component: () => import('@/views/legal/cookiespolicy/index.vue'),
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: () => import('@/views/aboutUs/index.vue'),
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),
  routes
})

const hasAction = (role: Role) => {
  return hasRole(role)
}

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  if (getToken()) {
    next()
  } else {
    console.log('to',to)
    if (to.name === 'home' || to.name === 'login' || to.name === 'signUp' || to.name === 'forgotPassword' || to.name === 'twoFa' 
      || to.name === 'miniAgreement' || to.name === 'StatementExport' || to.name === 'SimulateLogin' || to.name === 'agreement' || to.name === 'test'
      || to.name === 'KycCallback' || to.name === 'DeployKycCallback' || to.name === 'SignUpInformation' || to.name === 'MCE'
      || to.name === 'OurVision' || to.name === 'ProductsServices' || to.name === 'FAQ' || to.name === 'FAQMvgx' || to.name === 'FeeSchedule' || to.name === 'Disclosure' || to.name === 'Careers'
      || to.name === 'termsAndConditions' || to.name === 'privacyPolicy' || to.name === 'MarketTradeRuleView' || to.name === 'AboutUs'
      || to.name === 'investmentsAgreement' || to.name === 'Articles' || to.name === 'ArticlesDetail' || to.name === 'ArticlesDetailTwo' || to.name === 'ArticlesDetailThree' || to.name === 'TermsOfUse' || to.name === 'PrivacyPolicy' || to.name === 'CookiesPolicy') {
      next()
    } else {
      // debugger
      next('/login')
    }
  }


})


export default router
