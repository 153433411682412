import { IndividualList } from './../model/rootObject';
import { defineStore } from 'pinia'
import dayjs from 'dayjs';
import kycAPI from '@/api/kyc'
import { useProfileStore } from '@/stores/profile';
import { UploadProps } from 'ant-design-vue'

import profileApi from '@/api/profile'
import { useCurrencyStore } from './currency';
import stateFindVue from '@/views/portfolio/statement/components/stateFind.vue';
import { getUser } from "@/api/user"
import { Item } from 'ant-design-vue/lib/menu';
import { isMvgx } from '@/api/config';
import { useLoginStore } from '@/stores/login'
export const useKycStore = defineStore('kyc', {
    state: () => {
        return {
            currentStep: 0,
            isIframeFrom: false as boolean,//是否是iframe 访问form表单，不允许 提交
            type: 1, //个人 1， 企业 2
            corSelectPase3Key: 1,
            corSelectPase3Status: false,
            isDeclarationFormSumited: false,//企业税页面
            detailShow: false,
            from: {
                formId: null as number | null,
                kycType: null as number | null, // 1 .side
                tier: 1 as number | null,
                cat: null as number | null,
                status: null as number | null,
                step: null as number | null,
                phase2: false as boolean,
                phase3: false as boolean,
                expirationDate: null,
                submittedBy: null as number | null,
                individualDetail: {
                    id: null as number | null,
                    referred: 1,
                    fullName: '',
                    firstName: '',
                    lastName: '',
                    personalType: null,
                    passportNo: '',
                    birthDateContainer: null as any,
                    birthDate: '',
                    nationality: '' as string | number,
                    sgPr: 1,
                    birthCountry: '',
                    qualification: null,
                    mobileCode: "",
                    mobile: "",
                    telephoneCode: "",
                    telephone: "",
                    email: "",
                    mailingAddress: "",
                    mailingAddressOne: "",
                    mailingAddressTwo: "",

                    idCountry: "",
                    mailingPostalCode: "",
                    address: "",
                    addressOne: "",
                    addressTwo: "",
                    addressCountry: "",
                    postalCode: "",
                    idType: '1',
                    nonSingaporeIdType: '',
                    fileList: [] as any, // 1. fornt 2. back, 3 for passport, 4 for signature
                    idNumber: "",
                    employmentStatus: null,
                    employmentStatusOther: "",
                    positionHeld: "",
                    nameEmployer: "",
                    nature: "",
                    officeCountryCode: "",
                    officeNumber: "",
                    grossIncome: null,
                    addressEmployer: "",
                    employerCountryCode: "", // 后端缺字段
                    employerNumber: "",  // 后端缺字段
                    fundIdList: [],  // Source of your funds that will be used for trading
                    fundSourceOther: "",
                    wealthIdList: [], // Source of your accumulated wealth
                    wealthSourceOther: "",
                    wealthOrigin: null, // 后端缺字段
                    wealthOriginCountryCode: "", // 后端缺字段
                    // 税
                    incomeIdList: [],

                    signChecked: false,


                    tin: '',
                    taxPlace: 1,
                    tinType: 1,
                    ctcIdCard: 1,
                    ctcCertification: 1,

                    authorised: false,
                    signingChecked: false,
                    supportingChecked: false,
                    supportingReason: "",
                },
                tin: {
                    // 税
                    type: 1,
                    singaporeTin: "",
                    tin: "",
                    ssn: "", // 保存之前复制tin

                    countryOfTaxResidence: "",
                    countryTin: "", // 保存之前复制tin
                    noTinReasonId: null,
                    reasonForReasonB: "",
                    outCountry: 1, // 后端缺字段
                    outCountryOther: "", // 后端缺字段
                },
                tinList: [
                    {
                        countryOfTaxResidence: null,
                        tin: "",
                        noTinReasonId: null,
                        reasonForReasonB: "",
                    }
                ] as any,
                digits: [
                    {
                        value: ''
                    },
                    {
                        value: ''
                    },
                    {
                        value: ''
                    },
                    {
                        value: ''
                    },
                    {
                        value: ''
                    },
                    {
                        value: ''
                    },
                    {
                        value: ''
                    },
                    {
                        value: ''
                    },
                ],
                agreement: {
                    IncomeList: [-1, -1, -1],
                    income1: false,
                    income2: false,
                    income3: false,
                    r1: -1,
                    r2: -1,
                    r3: -1,
                    r3Text: "",
                    networth: null
                } as any,
                idCardImage: {
                    backSideImgId: "",
                    frontSideImgId: ""
                },
                uploadFiles: {
                    passportId: {
                        id: '',
                        name: '',
                        url: '',
                    },
                    //passport file
                    addressProofId: {
                        id: '',
                        name: '',
                        url: '',
                    }//单选

                },
                corpuploadFiles: [],
                corpDocument: {
                    passportList: [
                        { backSideImgId: "", frontSideImgId: "" },
                        { backSideImgId: "", frontSideImgId: "" },
                        { backSideImgId: "", frontSideImgId: "" }

                    ]
                },
                agreementList: [
                    {
                        serial: 1,
                        region: 1,
                        checkCode: 0,
                        detail: "",
                    },
                    {
                        serial: 2,
                        region: 1,
                        checkCode: 0,
                        detail: "",
                    },
                    {
                        serial: 3,
                        region: 1,
                        checkCode: 0,
                        detail: "",
                    },
                    {
                        serial: 1,
                        region: 2,
                        checkCode: 0,
                        detail: "",
                    },
                    {
                        serial: 2,
                        region: 2,
                        checkCode: 0,
                        detail: "",
                    },
                    {
                        serial: 3,
                        region: 2,
                        checkCode: 0,
                        detail: "",
                    },
                    {
                        serial: 1,
                        region: 3,
                        checkCode: 0,
                        detail: "",
                    }
                ],
                corporateInfo: {
                    // individualList: [
                    //     {
                    //         referred: 1,
                    //         fullName: '',
                    //         passportNo: '',
                    //         birthDateContainer: null as any,
                    //         birthDate: '',
                    //         nationality: "",
                    //         sgPr: 1,
                    //         birthCountry: "",
                    //         qualification: 1,
                    //         mobileCode: "",
                    //         mobile: "",
                    //         telephoneCode: "",
                    //         telephone: "",
                    //         email: "",
                    //         mailingAddress: "",
                    //         idCountry: "",
                    //         mailingPostalCode: "",
                    //         address: "",
                    //         addressCountry: "",
                    //         postalCode: "",
                    //         // fundIdList: [],  // Source of your funds that will be used for trading
                    //         // wealthIdList: [], // Source of your accumulated wealth
                    //     }
                    // ],
                    // applicantName: "Name of Corporation",
                    applicantName: "",
                    // registrationNo: "RCB Registration No. / Company Registration No.",
                    registrationNo: "",//去掉了 2022.9.9  //加回来了10.21
                    // legalNo: "Legal Entity Identifier No. (Lei)",
                    // legalNo: "",
                    businessCountry: "",
                    // place: "", //10月21，不要了
                    dateOfIncorporationDateContainer: null as any,
                    dateOfIncorporation: "",
                    countryCode: "",
                    telephone: "",
                    activity: "",
                    // email: "",
                    registeredAddress: "",
                    registeredAddressOne: "",
                    registeredAddressTwo: "",
                    businessAddress: "",
                    businessAddressOne: "",
                    businessAddressTwo: "",
                    mailingAddress: "",
                    mailingAddressOne: "",
                    mailingAddressTwo: "",
                    postalCountryCode: "",
                    postalCode: "",
                    fileList: [] as any,
                    addressProofList: [],
                    passportList: [] as any,
                    certificateType: null,
                    passportType: null,
                    agree: false,
                    proofIdList: [] as any,
                    ctcIdCard: 1,
                    ctcCertification: 1,
                    // 税页面字段
                    supportingReason: '',
                    hasBoard: false,
                    businessTypeOther: '',
                    buOrMaAddress: '',
                    buOrMaAddressOne: '',
                    buOrMaAddressTwo: '',
                    mailing: false,
                    id:null,
                    formId: null,
                    taxSupportingChecked: false,
                    taxSupportingReason: ''
                },
                individualList: [
                    // {
                    //     idType: '',
                    //     fileList: [],
                    //     ctcIdCard: null,
                    //     ctcCertification: null
                    // },
                ] as any,

                combinedIndividualList: [
                    // {
                    //     idType: '',
                    //     fileList: [],
                    //     ctcIdCard: null,
                    //     ctcCertification: null,
                    // },
                ] as any,

                shareholdersIndividualList: [
                    // {
                    //     idType: '',
                    //     fileList: [],
                    //     ctcIdCard: null,
                    //     ctcCertification: null,
                    // },
                ] as any,
                individualFileList: [] as any,
                combinedIndividualFileList: [] as any,
                shareholdersIndividualFileList: [] as any,
                scf: {
                    id:null,
                    formId:null,
                    entityType: 1,
                    optionOne: 1,
                    optionTwo: 1,
                    inputOne: '',
                    inputTwo: '',
                    tinList: [
                        {
                            id:null,
                            scfId:null,
                            personName: null,
                            personType: 1,
                            entityType: 1,
                            countryOfTaxResidence: null,
                            tin: "",
                            noTinReasonId: '',
                            reasonForReasonB: "",
                        }
                    ] as any,
                }

            },
            idtypeArr: {
                idType: '1',
                ctcIdCard: 1,
                ctcCertification: 1,
                frontList: [],
                backList: [],
                addressList: [],
                ctcPdfOneList: [],
                ctcImgOneList: [],
                ctcPdfTwoList: [],
                ctcImgTwoList: [],
                frontImgUrl: '',
                backImgUrl: '',
                ctcIdCardImgUrl: '',
                ctcCertificationImgUrl: '',
                shareholder: false,
                authorizedSignatory: false
            },
            sameCheck: false, //companyDetails1校验address是否相同
            samePersonCheck: false, //个人第一步
            sameContactCheck: false, //个人第一步
            agreeCheckOne: false, //companyDetails同意
            agreeCheckTwo: false, // upload supporting document 同意
            agreeCheckThree: false,
            ruleResideShow: false,
            // 已经上传的 idcard list
            idCardImageList: {
                frontSideImageId: '',
                backSideImageId: '',
            },
            uploadFilesList: {
                passportId: {
                    id: '',
                    name: '',
                    url: '',
                },
                //passport file
                addressProofId: {
                    id: '',
                    name: '',
                    url: '',
                }//单选

            },
            corpUploadFiles: {
                resolutionList: [] as any,
                certificationOneList: [] as any,
                includedOneList: [] as any,
                acrcList: [] as any,// 只有新加坡公司需要的
                incumbencyList: [] as any,
                authorityList: [] as any,
                memorandumList: [] as any,
                certificationTwoList: [] as any,
                includedTwoList: [] as any,
                signatoryList: [] as any,
                companyStructureList: [] as any,
                investorStatusList: [] as any,
                proffList: [] as any,
                boardList: [] as any,
                investorList: [] as any,

                // passportList: [{
                //     front: {
                //         id: -1,
                //         type: 5,
                //         fileName: ''
                //     },
                //     back: {
                //         id: -1,
                //         type: 6,
                //         fileName: ''
                //     }
                // }],  
            },
            corporateCard: {
                includedOneImageUrl: '',
                includedTwoImageUrl: '',
            },
            identityDocumentsTypeList: [
                { id: "1", name: "Identity Card (Pink)", nameZh: "新加坡人身份证（粉色）" },
                // { id: "2", name: "Singapore Identity Card (Blue)", nameZh: "新加坡永久居民身份证（蓝色）" },
                // { id: "3", name: "Passport (With more than 6 months validity)", nameZh: "护照（有效期在6个月以上）" },
            ],
            nonSingaporeIdentityDocumentsTypeList: [
                { id: "2", name: "Identity Card (Blue)", nameZh: "新加坡人身份证（蓝色）" },
                { id: "3", name: "Passport (With more than 6 months validity)", nameZh: "护照（有效期在6个月以上）" }
            ],
            employList: [
                { id: 1, name: "Employment Status", nameZh: "就业状况" },
                { id: 2, name: "Self-employed (Business Owner / Commission earner)", nameZh: "个体经营者（企业负责人/佣金收入者）" },
                { id: 3, name: "Unemployed", nameZh: "失业人员" },
            ],
            annualIncomeList: [
                { id: 1, name: "< S$30,000", nameZh: "低于新元30,000" },
                { id: 2, name: "S$300,000 to S$500,000", nameZh: "新元300,000 至 新元500,000" },
                { id: 3, name: "S$500,001 to S$750,000", nameZh: "新元500,001 至 新元750,000" },
                { id: 4, name: "S$750,001 to S$1 million", nameZh: "新元750,001 至 新元1百万" },
                { id: 5, name: "More than S$1 million", nameZh: "大于新元1百万" },
            ],


            incomeList: [
                {
                    value: 1, label: "I am an individual whose net personal assets exceed S$2 million (or its equivalent in a foreign currency), but net equity of primary residence not exceeding S$1 million net of outstanding loan.",
                    labelZh: "本人个人的资产净额超过200万新元(或等值外币)，但是主要居所净值扣除未偿还贷款后不超过100万新元"
                },
                {
                    value: 2, label: "I am an individual whose financial assets (net of any related liabilities) exceed in value S$1 million (or its equivalent in a foreign currency)",
                    labelZh: "本人个人的金融资产(扣除相关负债)价值超过100万新元(或等值外币)。"
                },
                {
                    value: 3, label: "I am an individual whose personal income in the preceeding 12 months is not less than S$300,000 (or its equivalent in a foreign currency)",
                    labelZh: "本人在过去12个月内个人收入不少于30万新元(或等值外币)。"
                },
            ],
            activeKey: 1,
            corpPersonalDetailsTab: 1,
            individuaInvestorlProofTab: 1,
            individualUploadFiles: {
                fontSideList: [] as any,
                backSideList: [] as any,
                passportList: [] as any,
                mailingAddressList: [] as any,
                profList: [] as any,
                addressPassportList: [] as any, //个人新加坡 第三页
                ctcIdCardList: [] as any, //个人 第三页
                includedThreeList: [] as any,
                documentsModeList: [] as any,
                documentsThreeList: [] as any,
            },
            individualCard: {
                fontSideImageUrl: '',
                bankSideImageUrl: '',
                passportImageUrl: '',
                passportFileList: [{
                    name: String,
                    id: String,
                }],
                addressProofFileList: [{
                    name: String,
                    id: String,
                }],
                profImageUrl: '',
                addressPassportImageUrl: '',
                includedThreeImageUrl: '',
                documentsThreeImageUrl: '',
            },
            additionalList: {},
            reqInfoList: [] as any,
            IPAList:[] as any,
            IPAFileList:[] as any,
            isIPASuccess: false,
            treeList:[],
        }
    },
    getters: {
        isIdentityCard: state => {
            return state.from.kycType == 1;
        },
        // corporatePersonal: state => {
        //     return state.from.corporateInfo.individualList[0]
        // },
        showFillStatus: state => {
            if (state.from.formId) // 已经填写过
            {
                return 2
            }
            return 1;
        },
        phase: state => {

            const profileStore = useProfileStore()
            if (profileStore.overview.tier == 2 && state.from.kycType == 2) {
                return 3
            } else {
                return profileStore.overview.tier
            }
        },
        idTypeList: state => {
            // if (state.from.kycType == 1) {
            if (state.from.individualDetail.sgPr == 2) {
                return state.nonSingaporeIdentityDocumentsTypeList
            }
            return state.identityDocumentsTypeList
        },
        identityTitle: state => {
            switch (state.from.kycType) {
                case 1:
                    return "Singaporeans & Singapore Permanent Residents Individual"
                case 2:
                    return "Non Singaporean"
                case 3:
                    return "Singaporean Corporate"
                case 4:
                    return "Non Singaporean Corporate"
            }
            return "Singaporeans & Singapore Permanent Residents Individual"
        }
    },
    actions: {
        loadForm() {
            const user = getUser();
            const loginStore = useLoginStore()
            if (user) {
                kycAPI.getForm().then(response => {
                    if (response) {
                        if (response.data) {
                            if(loginStore.signUpForm.kycAdmin){
                                this.from.formId = response.data.id;
                            }else{
                                this.from.formId = response.data.id;
                                if (response.data.kycType) {
                                    this.from.kycType = response.data.kycType;
                                    // this.getTree()
                                }

                                
                                this.from.tier = response.data.tier;
                                this.from.cat = response.data.cat;
                                this.from.status = response.data.status
                                this.from.step = response.data.step
                                this.from.phase2 = response.data.phase2
                                this.from.phase3 = response.data.phase3
                                this.from.expirationDate = response.data.expirationDate
                                this.from.submittedBy = response.data.submittedBy
                                if (this.from.kycType == 1 || this.from.kycType == 2) {
                                    this.type = 1
                                }

                                if (this.from.kycType == 3 || this.from.kycType == 4) {
                                    this.type = 2
                                }
                            }
                        }
                    }
                })
            }

        },

        getTree() {
            kycAPI.getTree().then(response => {
                if(response?.code == 0){
                    this.treeList = response.data
                    if( this.from.kycType == 3){
                        this.treeList.splice(1, 2)
                        this.treeList.splice(6, 1)
                    }
                    // console.log('this.treeList',this.treeList)
                }
            })
        },
        submitAgreement() {

            this.from.agreement;

            this.from.agreementList[0].checkCode = this.from.agreement.income1 ? 1 : 0
            this.from.agreementList[1].checkCode = this.from.agreement.income2 ? 1 : 0
            this.from.agreementList[2].checkCode = this.from.agreement.income3 ? 1 : 0
            this.from.agreementList[3].checkCode = this.from.agreement.r1
            this.from.agreementList[4].checkCode = this.from.agreement.r2
            this.from.agreementList[5].checkCode = this.from.agreement.r3
            this.from.agreementList[5].detail = this.from.agreement.r3Text
            this.from.agreementList[6].checkCode = this.from.agreement.networth ? 1 : 0

            const data = {
                tier: this.from.tier,
                cat: this.currentStep + 1,
                individualDetail: this.from.individualDetail,
                provisionsForm: this.from.agreementList,
                mvgx: isMvgx()
            }


            // api
            kycAPI.saveIndividualData(data).then(response => {
                this.loadIndividual()
                this.currentStep = this.currentStep + 1
            });
        },
        createForm() {
            // 如果form为空创建form
            if (this.from.formId == null) {
                if (this.from.kycType) {
                    kycAPI.createForm(this.from.kycType).then(response => {
                        if (response) {
                            this.from.formId = response.data.id;
                        }
                    })
                }
            }
        },
        loadIndividual() {

            // if (this.from.formId !== null) {


            kycAPI.loadIndividualForm().then(response => {

                if (response) {

                    const currencyStore = useCurrencyStore()
                    const loginStore = useLoginStore()
                    const request = { code: currencyStore.selected }
                    profileApi.getOverview(request).then(response => {
                        if (response) {

                            this.from.individualDetail.firstName = response.data.firstName
                            this.from.individualDetail.lastName = response.data.lastName
                        }
                    })

                    if (response.data.individualDetail) {
                        this.from.individualDetail = response.data.individualDetail;

                        if (response.data.individualDetail.birthDate) {
                            this.from.individualDetail.birthDateContainer = dayjs(response.data.individualDetail.birthDate)
                        }


                        // if(this.from.individualDetail.birthCountry == this.from.individualDetail.nationality){
                        //     this.samePersonCheck = true
                        // }else{
                        //     this.samePersonCheck = false
                        // }
                        // if(this.from.individualDetail.mailingAddress == this.from.individualDetail.address &&
                        // this.from.individualDetail.idCountry == this.from.individualDetail.addressCountry &&
                        // this.from.individualDetail.mailingPostalCode == this.from.individualDetail.postalCode) {
                        //     this.sameContactCheck = true
                        // }else{
                        //     this.sameContactCheck = false
                        // }




                        // this.from.individualDetail.nationality = 3
                        if (response.data.individualDetail.fileList) {
                            this.individualUploadFiles.fontSideList = []
                            this.individualUploadFiles.backSideList = []
                            this.individualUploadFiles.passportList = []
                            this.individualUploadFiles.mailingAddressList = []
                            this.individualUploadFiles.profList = []
                            this.individualUploadFiles.addressPassportList = []
                            this.individualUploadFiles.includedThreeList = []
                            this.individualUploadFiles.ctcIdCardList = []
                            this.individualUploadFiles.documentsModeList = []
                            this.individualUploadFiles.documentsThreeList = []
                            // this.from.individualDetail.fileList = response.data.individualDetail.fileList
                            response.data.individualDetail.fileList.forEach(async (item: any, index: number) => {
                                const url = await kycAPI.getDownloadUrl(item.id)
                                // loginStore.isClone ? const url =  kycAPI.downloadCloneFile({fileId: item.id, participantId: loginStore.participantId}) : const url = await kycAPI.getDownloadUrl(item.id)
                                
                                const sFile = {
                                    uid: item.id,
                                    name: item.fileName,
                                    status: 'done',
                                    url: url
                                }

                                if (item.type == 1) {
                                    this.individualCard.fontSideImageUrl = url
                                    this.individualUploadFiles.fontSideList.push(sFile)
                                } else if (item.type == 2) {
                                    this.individualCard.bankSideImageUrl = url
                                    this.individualUploadFiles.backSideList.push(sFile)
                                } else if (item.type == 3) {
                                    this.individualCard.passportImageUrl = url
                                    this.individualUploadFiles.passportList.push(sFile)
                                } else if (item.type == 4) {
                                    this.individualUploadFiles.mailingAddressList.push(sFile)
                                } else if (item.type == 5) {
                                    this.individualUploadFiles.profList.push(sFile)
                                } else if (item.type == 7) {
                                    this.individualUploadFiles.ctcIdCardList.push(sFile)
                                } else if (item.type == 8) {
                                    this.individualCard.includedThreeImageUrl = url
                                    this.individualUploadFiles.includedThreeList.push(sFile)
                                } else if (item.type == 9) {
                                    this.individualUploadFiles.documentsModeList.push(sFile)
                                } else if (item.type == 10) {
                                    this.individualCard.documentsThreeImageUrl = url
                                    this.individualUploadFiles.documentsThreeList.push(sFile)
                                } else {
                                    this.individualCard.addressPassportImageUrl = url
                                    this.individualUploadFiles.addressPassportList.push(sFile)
                                }
                            })
                        }
                    }
                    if (response.data.tin) {
                        this.from.tin = response.data.tin
                        if (response.data.tin.type == 1) {
                            this.from.tin.singaporeTin = response.data.tin.tin
                        }
                        if (response.data.tin.type == 2) {
                            response.data.tin.tin.split("").forEach((item: any, index: number) => {
                                this.from.digits[index].value = item
                            })
                        }
                        if (response.data.tin.type == 3) {
                            this.from.tin.countryTin = response.data.tin.tin
                        }
                    }
                    if (response.data.tinList && response.data.tinList.length > 0) {
                        this.from.tinList = response.data.tinList
                    }
                    
                    if (response.data.provisionsForm && response.data.provisionsForm.length > 0) {
                        if (response.data.provisionsForm[0].checkCode == 1) {
                            this.from.agreement.income1 = true
                        } else {
                            this.from.agreement.income1 = false
                        }
                        if (response.data.provisionsForm[1].checkCode == 1) {
                            this.from.agreement.income2 = true
                        } else {
                            this.from.agreement.income2 = false
                        }
                        if (response.data.provisionsForm[2].checkCode == 1) {
                            this.from.agreement.income3 = true
                        } else {
                            this.from.agreement.income3 = false
                        }

                        this.from.agreement.r1 = response.data.provisionsForm[3].checkCode
                        this.from.agreement.r2 = response.data.provisionsForm[4].checkCode
                        this.from.agreement.r3 = response.data.provisionsForm[5].checkCode
                        this.from.agreement.r3Text = response.data.provisionsForm[5].detail
                        this.from.agreement.networth = response.data.provisionsForm[6].checkCode
                    }
                    if (this.from.kycType == 2) {
                        this.from.individualDetail.nonSingaporeIdType = response.data.individualDetail.idType
                    }


                }


            })
            // }
        },
        saveIndividualData() {
            if (this.from.individualDetail.birthDateContainer) {
                this.from.individualDetail.birthDate = this.from.individualDetail.birthDateContainer.format('YYYY-MM-DD')
            }
            this.from.individualDetail.fileList = []
            // todo

            for (let i = 0; i < this.individualUploadFiles.fontSideList.length; i++) {
                const file = this.individualUploadFiles.fontSideList[i] as any;
                const uid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: uid,
                    type: 1,
                }
                this.from.individualDetail.fileList.push(sFile)
            }
            for (let i = 0; i < this.individualUploadFiles.backSideList.length; i++) {
                const file = this.individualUploadFiles.backSideList[i] as any;
                const uid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: uid,
                    type: 2,
                }
                this.from.individualDetail.fileList.push(sFile)
            }
            for (let i = 0; i < this.individualUploadFiles.passportList.length; i++) {
                const file = this.individualUploadFiles.passportList[i] as any;
                const uid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: uid,
                    type: 3,
                }
                this.from.individualDetail.fileList.push(sFile)
            }
            for (let i = 0; i < this.individualUploadFiles.mailingAddressList.length; i++) {
                const file = this.individualUploadFiles.mailingAddressList[i] as any;
                const uid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: uid,
                    type: 4,
                }
                this.from.individualDetail.fileList.push(sFile)
            }
            for (let i = 0; i < this.individualUploadFiles.profList.length; i++) {
                const file = this.individualUploadFiles.profList[i] as any;
                const uid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: uid,
                    type: 5,
                }
                this.from.individualDetail.fileList.push(sFile)
            }
            for (let i = 0; i < this.individualUploadFiles.addressPassportList.length; i++) {
                const file = this.individualUploadFiles.addressPassportList[i] as any;
                const uid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: uid,
                    type: 6,
                }
                this.from.individualDetail.fileList.push(sFile)
            }

            for (let i = 0; i < this.individualUploadFiles.ctcIdCardList.length; i++) {
                const file = this.individualUploadFiles.ctcIdCardList[i] as any;
                const uid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: uid,
                    type: 7,
                }
                this.from.individualDetail.fileList.push(sFile)
            }

            for (let i = 0; i < this.individualUploadFiles.includedThreeList.length; i++) {
                const file = this.individualUploadFiles.includedThreeList[i] as any;
                const uid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: uid,
                    type: 8,
                }
                this.from.individualDetail.fileList.push(sFile)
            }

            for (let i = 0; i < this.individualUploadFiles.documentsModeList.length; i++) {
                const file = this.individualUploadFiles.documentsModeList[i] as any;
                const uid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: uid,
                    type: 9,
                }
                this.from.individualDetail.fileList.push(sFile)
            }

            for (let i = 0; i < this.individualUploadFiles.documentsThreeList.length; i++) {
                const file = this.individualUploadFiles.documentsThreeList[i] as any;
                const uid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: uid,
                    type: 10,
                }
                this.from.individualDetail.fileList.push(sFile)
            }

            this.from.individualDetail.fullName = this.from.individualDetail.firstName + this.from.individualDetail.lastName

            const data = {
                tier: this.from.tier,
                individualDetail: this.from.individualDetail,
                cat: this.currentStep + 1,
                tinList: this.from.tinList,
                mvgx: isMvgx()
            }
            // data.individualDetail.fileList = []

            const userData = {
                firstName: this.from.individualDetail.firstName,
                lastName: this.from.individualDetail.lastName,
                // address: '',
            }

            profileApi.updateUser(userData).then(response => {
                if (response?.code == 0) {
                    // store.loadOverview()
                }
            })

            kycAPI.saveIndividualData(data).then(response => {
                this.loadIndividual()
                this.loadForm()
                // if (this.currentStep == 3) {
                //     this.individuaInvestorlProofTab = this.individuaInvestorlProofTab + 1
                // } else {
                this.currentStep = this.currentStep + 1
                // }
            }, err => {
                console.log(err, 'errr0')
            });


        },

        saveTinData() {
            const data = {
                tier: this.from.tier,
                cat: this.currentStep + 1,
                individualDetail: this.from.individualDetail,
                // tin: this.from.tin
                tinList: this.from.tinList,
                mvgx: isMvgx()
            }
            kycAPI.saveIndividualData(data).then(response => {
                this.loadIndividual()
                // if (this.currentStep == 3) {
                //     this.individuaInvestorlProofTab = this.individuaInvestorlProofTab + 1
                // }
                this.currentStep = this.currentStep + 1
            })
        },
        loadCorporateInfo() {
            // if (this.from.formId == null) {
            kycAPI.getInfo().then(response => {
                if (response) {
                    console.log('loadCorporateInfo-------------------------',response.data)
                    if(response.data.kycType){
                        this.from.kycType = response.data.kycType
                        // this.getTree()
                    }
                    if(response.data.submittedBy){
                        this.from.submittedBy = response.data.submittedBy
                        // this.getTree()
                    }
                    const loginStore = useLoginStore()
                    if (response.data.corporateInfo) {
                        if (response.data.corporateInfo.fileList) {
                            this.corpUploadFiles.resolutionList = []
                            this.corpUploadFiles.certificationOneList = []
                            this.corpUploadFiles.includedOneList = []
                            this.corpUploadFiles.acrcList = []
                            this.corpUploadFiles.incumbencyList = []
                            this.corpUploadFiles.authorityList = []
                            this.corpUploadFiles.memorandumList = []
                            this.corpUploadFiles.certificationTwoList = []
                            this.corpUploadFiles.includedTwoList = []
                            this.corpUploadFiles.signatoryList = []
                            this.corpUploadFiles.companyStructureList = []
                            this.corpUploadFiles.investorStatusList = []
                            this.corpUploadFiles.proffList = []
                            this.corpUploadFiles.boardList = []
                            this.corpUploadFiles.investorList = []

                            response.data.corporateInfo.fileList.forEach(async (item: any, index: number) => {
                                let file = {
                                    uid: item.id,
                                    name: item.fileName,
                                    status: 'done',
                                    url: ''
                                }
                                if(loginStore.isClone){
                                    console.log('loginStore.loadFirst',loginStore.loadFirst,this.from.submittedBy)
                                    const url = await kycAPI.downloadCloneFile({fileId: item.id, participantId: loginStore.loadFirst ? loginStore.participantId : this.from.submittedBy})
                                    file.url = url
                                }else{
                                    const url = await kycAPI.getDownloadUrl(item.id)
                                    file.url = url
                                }

                                if (item.type == 1) {
                                    this.corpUploadFiles.resolutionList.push(file)
                                }
                                if (item.type == 2) {
                                    this.corpUploadFiles.certificationOneList.push(file)
                                }
                                if (item.type == 3) {
                                    this.corpUploadFiles.includedOneList.push(file)
                                    this.corporateCard.includedOneImageUrl = file.url
                                }
                                if (item.type == 4) {
                                    this.corpUploadFiles.acrcList.push(file)
                                }
                                if (item.type == 5) {
                                    this.corpUploadFiles.incumbencyList.push(file)
                                }
                                if (item.type == 6) {
                                    this.corpUploadFiles.authorityList.push(file)
                                }
                                if (item.type == 7) {
                                    this.corpUploadFiles.memorandumList.push(file)
                                }
                                if (item.type == 8) {
                                    this.corpUploadFiles.certificationTwoList.push(file)
                                }
                                if (item.type == 9) {
                                    this.corpUploadFiles.includedTwoList.push(file)
                                    this.corporateCard.includedTwoImageUrl = file.url
                                }
                                if (item.type == 10) {

                                    this.corpUploadFiles.signatoryList.push(file)
                                }
                                if (item.type == 11) {
                                    this.corpUploadFiles.companyStructureList.push(file)
                                }
                                if (item.type == 12) {
                                    this.corpUploadFiles.investorStatusList.push(file)
                                }
                                if (item.type == 13) {
                                    this.corpUploadFiles.proffList.push(file)
                                }
                                if (item.type == 14) {
                                    this.corpUploadFiles.boardList.push(file)
                                }
                                if (item.type == 15) {
                                    this.corpUploadFiles.investorList.push(file)
                                }
                            });
                        }
                        
                        this.from.corporateInfo = response.data.corporateInfo;
                        if(loginStore.isClone && this.currentStep+1 == 1 ){
                            Reflect.deleteProperty(this.from.corporateInfo,"id");
                            Reflect.deleteProperty(this.from.corporateInfo,"formId");
                        }
                        this.from.corporateInfo.buOrMaAddress = response.data.corporateInfo.mailingAddress || response.data.corporateInfo.businessAddress
                        this.from.corporateInfo.buOrMaAddressOne = response.data.corporateInfo.mailingAddressOne || response.data.corporateInfo.businessAddressOne
                        this.from.corporateInfo.buOrMaAddressTwo = response.data.corporateInfo.mailingAddressTwo || response.data.corporateInfo.businessAddressTwo
                        if (response.data.corporateInfo.dateOfIncorporation) {
                            this.from.corporateInfo.dateOfIncorporationDateContainer = dayjs(response.data.corporateInfo.dateOfIncorporation)
                        }

                        
                    }

                
                    if (response.data.tinList && response.data.tinList.length > 0) {
                        this.from.tinList = response.data.tinList
                        console.log('this.from.tinList',this.from.tinList)
                        if(loginStore.isClone && this.currentStep+1 == 1){
                            this.from.tinList.forEach((item: any,index: any) => {
                                item.id = null
                            })
                        }

                    }
                    if (response.data.scf) {
                        this.from.scf = response.data.scf
                        
                        if(loginStore.isClone && this.currentStep+1 == 1){
                            this.from.scf.id = null
                            this.from.scf.formId = null
                            if(this.from.scf.tinList.length > 0){
                                this.from.scf.tinList.forEach((item: any,index: any) => {
                                    item.id = null
                                    item.scfId = null
                                })
                            }
                        }
                        console.log('this.from.scf',this.from.scf,this.from.scf.tinList)

                    }
                    
                    this.from.individualFileList = []
                    if(response.data.individualList && response.data.individualList.length > 0){
                        
                            for (let i = 0; i < response.data.individualList.length; i++) {
                                const individual = response.data.individualList[i]
                                let dataArr = {
                                    idType: individual.idType,
                                    ctcIdCard: individual.ctcIdCard,
                                    ctcCertification: individual.ctcCertification,
                                    frontList: [] as any,
                                    backList: [] as any,
                                    addressList: [] as any,
                                    ctcPdfOneList: [] as any,
                                    ctcImgOneList: [] as any,
                                    ctcPdfTwoList: [] as any,
                                    ctcImgTwoList: [] as any,
                                    ctcIdCardImgUrl: '',
                                    ctcCertificationImgUrl: '',
                                    frontImageUrl: '',
                                    backImageUrl: '',
                                    shareholder: individual.shareholder,
                                    authorizedSignatory: individual.authorizedSignatory,
                                }
                                const arr:any = [];
                                individual.fileList.forEach((item:any) => {
                                    if(loginStore.isClone){
                                        const api = kycAPI.downloadCloneFile({fileId: item.id, participantId: loginStore.loadFirst ? loginStore.participantId : this.from.submittedBy})
                                        console.log('apiClone---',api)
                                        arr.push(api)
                                        console.log('arrClone----',arr)
                                    }else{
                                        const api = kycAPI.getDownloadUrl(item.id)
                                        console.log('api---',api)
                                        arr.push(api)
                                        console.log('arr----',arr)
                                    }
                                })
                                Promise.all(arr).then((result: any) => {
                                    console.log(result,'555555559999')
                                    result.forEach((item:any, index:number) => {
                                        individual.fileList[index].url = item;
                                    })
                                    // debugger
                                    individual.fileList.forEach((item: any, index: number) => {
                                        //const url = await kycAPI.getDownloadUrl(item.id)
                                        console.log('item',item,index)
                                        const file = {
                                            uid: item.id,
                                            name: item.fileName,
                                            status: 'done',
                                            url: item.url
                                        }
                                        if (item.type == 1) {
                                            dataArr.frontList.push(file)
                                            dataArr.frontImageUrl = item.url
                                        }
                                        if (item.type == 2) {
                                            dataArr.backList.push(file)
                                            dataArr.backImageUrl = item.url
                                        }
                                        if (item.type == 3) {
                                            dataArr.addressList.push(file)
                                        }
                                        if (item.type == 4) {
                                            dataArr.ctcPdfOneList.push(file)
                                        }
                                        if (item.type == 5) {
                                            dataArr.ctcImgOneList.push(file)
                                            dataArr.ctcIdCardImgUrl = item.url
                                        }
                                        if (item.type == 6) {
                                            dataArr.ctcPdfTwoList.push(file)
                                        }
                                        if (item.type == 7) {
                                            dataArr.ctcImgTwoList.push(file)
                                            dataArr.ctcCertificationImgUrl = item.url
                                        }
                                        if (index == individual.fileList.length - 1) {
                                            this.from.individualFileList.push(dataArr)
                                        }
                                    })
                                    
                                })
                            }
                                //this.from.individualFileList.push(dataArr)                          

                        
                    }
                    
                    this.from.combinedIndividualFileList = []
                    if(response.data.combinedIndividualList && response.data.combinedIndividualList.length > 0){
                        for (let i = 0; i < response.data.combinedIndividualList.length; i++) {
                            const individual = response.data.combinedIndividualList[i]
                            let dataArr = {
                                idType: individual.idType,
                                ctcIdCard: individual.ctcIdCard,
                                ctcCertification: individual.ctcCertification,
                                frontList: [] as any,
                                backList: [] as any,
                                addressList: [] as any,
                                ctcPdfOneList: [] as any,
                                ctcImgOneList: [] as any,
                                ctcPdfTwoList: [] as any,
                                ctcImgTwoList: [] as any,
                                ctcIdCardImgUrl: '',
                                ctcCertificationImgUrl: '',
                                frontImageUrl: '',
                                backImageUrl: '',
                                shareholder: individual.shareholder,
                                authorizedSignatory: individual.authorizedSignatory,
                            }
                            const arr:any = [];
                            individual.fileList.forEach((item:any) => {
                                if(loginStore.isClone){
                                    const api = kycAPI.downloadCloneFile({fileId: item.id, participantId: loginStore.loadFirst ? loginStore.participantId : this.from.submittedBy})
                                    arr.push(api)
                                }else{
                                    const api = kycAPI.getDownloadUrl(item.id)
                                    arr.push(api)
                                }
                                // const api = kycAPI.getDownloadUrl(item.id)
                                // arr.push(api)
                            })
                            Promise.all(arr).then((result: any) => {
                                console.log(result,'555555559999')
                                result.forEach((item:any, index:number) => {
                                    individual.fileList[index].url = item;
                                })
                                individual.fileList.forEach((item: any, index: number) => {
                                    const file = {
                                        uid: item.id,
                                        name: item.fileName,
                                        status: 'done',
                                        url: item.url
                                    }
                                    if (item.type == 1) {
                                        dataArr.frontList.push(file)
                                        dataArr.frontImageUrl = item.url
                                    }
                                    if (item.type == 2) {
                                        dataArr.backList.push(file)
                                        dataArr.backImageUrl = item.url
                                    }
                                    if (item.type == 3) {
                                        dataArr.addressList.push(file)
                                    }
                                    if (item.type == 4) {
                                        dataArr.ctcPdfOneList.push(file)
                                    }
                                    if (item.type == 5) {
                                        dataArr.ctcImgOneList.push(file)
                                        dataArr.ctcIdCardImgUrl = item.url
                                    }
                                    if (item.type == 6) {
                                        dataArr.ctcPdfTwoList.push(file)
                                    }
                                    if (item.type == 7) {
                                        dataArr.ctcImgTwoList.push(file)
                                        dataArr.ctcCertificationImgUrl = item.url
                                    }
                                    if (index == individual.fileList.length - 1) {
                                        this.from.combinedIndividualFileList.push(dataArr)
                                    }
    
                                })
                            })
                            
        
                            
                        }
                    }
                    
                    this.from.shareholdersIndividualFileList = []
                    if(response.data.shareholdersIndividualList && response.data.shareholdersIndividualList.length > 0){
                        for (let i = 0; i < response.data.shareholdersIndividualList.length; i++) {
                            const individual = response.data.shareholdersIndividualList[i]
                            let dataArr = {
                                idType: individual.idType,
                                ctcIdCard: individual.ctcIdCard,
                                ctcCertification: individual.ctcCertification,
                                frontList: [] as any,
                                backList: [] as any,
                                addressList: [] as any,
                                ctcPdfOneList: [] as any,
                                ctcImgOneList: [] as any,
                                ctcPdfTwoList: [] as any,
                                ctcImgTwoList: [] as any,
                                ctcIdCardImgUrl: '',
                                ctcCertificationImgUrl: '',
                                frontImageUrl: '',
                                backImageUrl: '',
                                shareholder: individual.shareholder,
                                authorizedSignatory: individual.authorizedSignatory,
                            }
                            const arr:any = [];
                            individual.fileList.forEach((item:any) => {
                                if(loginStore.isClone){
                                    const api = kycAPI.downloadCloneFile({fileId: item.id, participantId: loginStore.loadFirst ? loginStore.participantId : this.from.submittedBy})
                                    arr.push(api)
                                }else{
                                    const api = kycAPI.getDownloadUrl(item.id)
                                    arr.push(api)
                                }
                            // const api = kycAPI.getDownloadUrl(item.id)
                            //     arr.push(api)
                            })
                            Promise.all(arr).then((result: any) => {
                                console.log(result,'555555559999')
                                result.forEach((item:any, index:number) => {
                                    individual.fileList[index].url = item;
                                })
                                individual.fileList.forEach((item: any, index: number) => {
                                    const file = {
                                        uid: item.id,
                                        name: item.fileName,
                                        status: 'done',
                                        url: item.url
                                    }
                                    if (item.type == 1) {
                                        dataArr.frontList.push(file)
                                        dataArr.frontImageUrl = item.url
                                    }
                                    if (item.type == 2) {
                                        dataArr.backList.push(file)
                                        dataArr.backImageUrl = item.url
                                    }
                                    if (item.type == 3) {
                                        dataArr.addressList.push(file)
                                    }
                                    if (item.type == 4) {
                                        dataArr.ctcPdfOneList.push(file)
                                    }
                                    if (item.type == 5) {
                                        dataArr.ctcImgOneList.push(file)
                                        dataArr.ctcIdCardImgUrl = item.url
                                    }
                                    if (item.type == 6) {
                                        dataArr.ctcPdfTwoList.push(file)
                                    }
                                    if (item.type == 7) {
                                        dataArr.ctcImgTwoList.push(file)
                                        dataArr.ctcCertificationImgUrl = item.url
                                    }
                                    if (index == individual.fileList.length - 1) {
                                        this.from.shareholdersIndividualFileList.push(dataArr)
                                    }
                                })
                            })
                            
                        }
                    }
                    // if(this.from.shareholdersIndividualFileList.length == 0){
                    //     this.from.shareholdersIndividualFileList.push(JSON.parse(JSON.stringify(this.idtypeArr)))
                    // }

                
                }

            })
        },
        infoSave() {
            const loginStore = useLoginStore()
            // 这里处理数据转换
            // 文件上传处理
            this.from.corporateInfo.fileList = []
            for (let i = 0; i < this.corpUploadFiles.resolutionList.length; i++) {
                const file = this.corpUploadFiles.resolutionList[i] as any;
                const fid = file.response == null ? file.uid : file.response.data.id

                const sFile = {
                    id: fid,
                    type: 1,
                    fileName: ''
                }
                this.from.corporateInfo.fileList.push(sFile)
            }

            for (let i = 0; i < this.corpUploadFiles.certificationOneList.length; i++) {
                const file = this.corpUploadFiles.certificationOneList[i] as any;
                const fid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: fid,
                    type: 2,
                    fileName: ''
                }
                this.from.corporateInfo.fileList.push(sFile)
            }
            for (let i = 0; i < this.corpUploadFiles.includedOneList.length; i++) {
                const file = this.corpUploadFiles.includedOneList[i] as any;
                const fid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: fid,
                    type: 3,
                    fileName: ''
                }
                this.from.corporateInfo.fileList.push(sFile)
            }

            for (let i = 0; i < this.corpUploadFiles.acrcList.length; i++) {
                const file = this.corpUploadFiles.acrcList[i] as any;
                const fid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: fid,
                    type: 4,
                    fileName: ''
                }
                this.from.corporateInfo.fileList.push(sFile)
            }

            for (let i = 0; i < this.corpUploadFiles.incumbencyList.length; i++) {
                const file = this.corpUploadFiles.incumbencyList[i] as any;
                const fid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: fid,
                    type: 5,
                    fileName: ''
                }
                this.from.corporateInfo.fileList.push(sFile)
            }

            for (let i = 0; i < this.corpUploadFiles.authorityList.length; i++) {
                const file = this.corpUploadFiles.authorityList[i] as any;
                const fid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: fid,
                    type: 6,
                    fileName: ''
                }
                this.from.corporateInfo.fileList.push(sFile)
            }

            for (let i = 0; i < this.corpUploadFiles.memorandumList.length; i++) {
                const file = this.corpUploadFiles.memorandumList[i] as any;
                const fid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: fid,
                    type: 7,
                    fileName: ''
                }
                this.from.corporateInfo.fileList.push(sFile)
            }

            for (let i = 0; i < this.corpUploadFiles.certificationTwoList.length; i++) {
                const file = this.corpUploadFiles.certificationTwoList[i] as any;
                const fid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: fid,
                    type: 8,
                    fileName: ''
                }
                this.from.corporateInfo.fileList.push(sFile)
            }

            for (let i = 0; i < this.corpUploadFiles.includedTwoList.length; i++) {
                const file = this.corpUploadFiles.includedTwoList[i] as any;
                const fid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: fid,
                    type: 9,
                    fileName: ''
                }
                this.from.corporateInfo.fileList.push(sFile)
            }



            for (let i = 0; i < this.corpUploadFiles.signatoryList.length; i++) {
                const file = this.corpUploadFiles.signatoryList[i] as any;
                const fid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: fid,
                    type: 10,
                    fileName: ''
                }
                this.from.corporateInfo.fileList.push(sFile)
            }



            for (let i = 0; i < this.corpUploadFiles.companyStructureList.length; i++) {
                const file = this.corpUploadFiles.companyStructureList[i] as any;
                const fid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: fid,
                    type: 11,
                    fileName: ''
                }
                this.from.corporateInfo.fileList.push(sFile)
            }

            if (this.corpUploadFiles.investorStatusList && this.corpUploadFiles.investorStatusList.length > 0) {
                for (let i = 0; i < this.corpUploadFiles.investorStatusList.length; i++) {
                    const file = this.corpUploadFiles.investorStatusList[i] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 12,
                        fileName: ''
                    }
                    this.from.corporateInfo.fileList.push(sFile)
                }
                this.from.corporateInfo.hasBoard = true
            } else {
                this.from.corporateInfo.hasBoard = false
            }

            for (let i = 0; i < this.corpUploadFiles.proffList.length; i++) {
                const file = this.corpUploadFiles.proffList[i] as any;
                const fid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: fid,
                    type: 13,
                    fileName: ''
                }
                this.from.corporateInfo.fileList.push(sFile)
            }

            for (let i = 0; i < this.corpUploadFiles.boardList.length; i++) {
                const file = this.corpUploadFiles.boardList[i] as any;
                const fid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: fid,
                    type: 14,
                    fileName: ''
                }
                this.from.corporateInfo.fileList.push(sFile)
            }

            for (let i = 0; i < this.corpUploadFiles.investorList.length; i++) {
                const file = this.corpUploadFiles.investorList[i] as any;
                const fid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    id: fid,
                    type: 15,
                    fileName: ''
                }
                this.from.corporateInfo.fileList.push(sFile)
            }
            
            this.from.individualList = []
            for (let i = 0; i < this.from.individualFileList.length; i++) {
                let fileList = []
                let individual = this.from.individualFileList[i]
                let frontList = individual.frontList
                for (let j = 0; j < frontList.length; j++) {
                    const file = frontList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 1,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }
                let backList = individual.backList
                for (let j = 0; j < backList.length; j++) {
                    const file = backList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 2,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }
                let addressList = individual.addressList
                for (let j = 0; j < addressList.length; j++) {
                    const file = addressList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 3,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }
                let ctcPdfOneList = individual.ctcPdfOneList
                for (let j = 0; j < ctcPdfOneList.length; j++) {
                    const file = ctcPdfOneList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 4,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }
                let ctcImgOneList = individual.ctcImgOneList
                for (let j = 0; j < ctcImgOneList.length; j++) {
                    const file = ctcImgOneList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 5,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }
                let ctcPdfTwoList = individual.ctcPdfTwoList
                for (let j = 0; j < ctcPdfTwoList.length; j++) {
                    const file = ctcPdfTwoList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 6,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }
                let ctcImgTwoList = individual.ctcImgTwoList
                for (let j = 0; j < ctcImgTwoList.length; j++) {
                    const file = ctcImgTwoList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 7,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }

                let idType = individual.idType
                let ctcIdCard = individual.ctcIdCard
                let ctcCertification = individual.ctcCertification
                let shareholder = individual.shareholder
                let authorizedSignatory = individual.authorizedSignatory
                let dataArr = {
                    idType: idType,
                    fileList: fileList,
                    ctcIdCard: ctcIdCard,
                    ctcCertification: ctcCertification,
                    shareholder: shareholder,
                    authorizedSignatory: authorizedSignatory,
                }
                this.from.individualList.push(dataArr)
            }
            this.from.combinedIndividualList = []
            for (let i = 0; i < this.from.combinedIndividualFileList.length; i++) {
                let fileList = []
                let individual = this.from.combinedIndividualFileList[i]
                let frontList = individual.frontList
                for (let j = 0; j < frontList.length; j++) {
                    const file = frontList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 1,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }
                let backList = individual.backList
                for (let j = 0; j < backList.length; j++) {
                    const file = backList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 2,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }
                let addressList = individual.addressList
                for (let j = 0; j < addressList.length; j++) {
                    const file = addressList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 3,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }
                let ctcPdfOneList = individual.ctcPdfOneList
                for (let j = 0; j < ctcPdfOneList.length; j++) {
                    const file = ctcPdfOneList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 4,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }
                let ctcImgOneList = individual.ctcImgOneList
                for (let j = 0; j < ctcImgOneList.length; j++) {
                    const file = ctcImgOneList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 5,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }
                let ctcPdfTwoList = individual.ctcPdfTwoList
                for (let j = 0; j < ctcPdfTwoList.length; j++) {
                    const file = ctcPdfTwoList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 6,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }
                let ctcImgTwoList = individual.ctcImgTwoList
                for (let j = 0; j < ctcImgTwoList.length; j++) {
                    const file = ctcImgTwoList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 7,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }

                let idType = individual.idType
                let ctcIdCard = individual.ctcIdCard
                let ctcCertification = individual.ctcCertification
                let shareholder = individual.shareholder
                let authorizedSignatory = individual.authorizedSignatory
                let dataArr = {
                    idType: idType,
                    fileList: fileList,
                    ctcIdCard: ctcIdCard,
                    ctcCertification: ctcCertification,
                    shareholder: shareholder,
                    authorizedSignatory: authorizedSignatory,
                }
                this.from.combinedIndividualList.push(dataArr)
            }
        
            this.from.shareholdersIndividualList = []
            for (let i = 0; i < this.from.shareholdersIndividualFileList.length; i++) {
                let fileList = []
                let individual = this.from.shareholdersIndividualFileList[i]
                let frontList = individual.frontList
                for (let j = 0; j < frontList.length; j++) {
                    const file = frontList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 1,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }
                let backList = individual.backList
                for (let j = 0; j < backList.length; j++) {
                    const file = backList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 2,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }
                let addressList = individual.addressList
                for (let j = 0; j < addressList.length; j++) {
                    const file = addressList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 3,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }
                let ctcPdfOneList = individual.ctcPdfOneList
                for (let j = 0; j < ctcPdfOneList.length; j++) {
                    const file = ctcPdfOneList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 4,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }
                let ctcImgOneList = individual.ctcImgOneList
                for (let j = 0; j < ctcImgOneList.length; j++) {
                    const file = ctcImgOneList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 5,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }
                let ctcPdfTwoList = individual.ctcPdfTwoList
                for (let j = 0; j < ctcPdfTwoList.length; j++) {
                    const file = ctcPdfTwoList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 6,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }
                let ctcImgTwoList = individual.ctcImgTwoList
                for (let j = 0; j < ctcImgTwoList.length; j++) {
                    const file = ctcImgTwoList[j] as any;
                    const fid = file.response == null ? file.uid : file.response.data.id
                    const sFile = {
                        id: fid,
                        type: 7,
                        fileName: ''
                    }
                    fileList.push(sFile)
                }

                let idType = individual.idType
                let ctcIdCard = individual.ctcIdCard
                let ctcCertification = individual.ctcCertification
                let shareholder = individual.shareholder
                let authorizedSignatory = individual.authorizedSignatory
                let dataArr = {
                    idType: idType,
                    fileList: fileList,
                    ctcIdCard: ctcIdCard,
                    ctcCertification: ctcCertification,
                    shareholder: shareholder,
                    authorizedSignatory: authorizedSignatory,
                }
                this.from.shareholdersIndividualList.push(dataArr)
            }

            // 新加的taxSupportingReason字段， 其实就是之前的supportingReason字段 页面展示supportingReason 传参这俩都传（supportingReason以前存在 不敢删 :(）
            this.from.corporateInfo.taxSupportingReason = this.from.corporateInfo.supportingReason
            const data = {
                tier: this.from.tier,
                corporateInfo: this.from.corporateInfo,
                cat: this.currentStep + 1,
                individualList: this.from.individualList,
                combinedIndividualList: this.from.combinedIndividualList,
                shareholdersIndividualList: this.from.shareholdersIndividualList,
                tinList: this.from.tinList,
                scf: this.from.scf,
                mvgx: isMvgx()
            }

            kycAPI.infoSave(data).then(response => {
                if (response?.code == 0) {
                    // next step
                    setTimeout(() => {
                        this.currentStep = this.currentStep + 1
                        this.loadCorporateInfo()
                        this.loadForm()
                        loginStore.loadFirst = ''
                    },100)
                    
                }
            })
        },
        // addPossport() {
        //     this.corpUploadFiles.passportList.push({
        //         front: {
        //             id: -1,
        //             type: 10,
        //             fileName: ''
        //         },
        //         back: {
        //             id: -1,
        //             type: 11,
        //             fileName: ''
        //         }
        //     })
        // },
        // deletePassport(idx: number) {
        //     this.corpUploadFiles.passportList.splice(idx, 1)
        // },

        loadAdditional() {
            kycAPI.getAdditional().then(response => {
                if (response?.code == 0) {
                    if (response.data.list) {
                        this.additionalList = response.data.list
                        response.data.list.forEach(async (item: any, index: number) => {
                            if (item.fileList) {
                                const url = await kycAPI.getDownloadUrl(item.fileList[0].id)
                                item.fileList[0].url = url
                            }
                        })
                    }
                }
            })
        },
        saveAdditional() {
            const request = {
                reqInfoList: this.additionalList
            }
            kycAPI.saveAdditional(request).then(response => {
                if (response?.code == 0) {
                    this.loadAdditional()
                }
            })
        },
        loadIPA(){
            kycAPI.queryIPAFile({}).then(response => {

            })
        },
        saveIPAFile(){
            this.IPAList = []
            for (let i = 0; i < this.IPAFileList.length; i++) {
                const file = this.IPAFileList[i] as any;
                const fid = file.response == null ? file.uid : file.response.data.id
                const sFile = {
                    formId:  this.from.formId,
                    fileId: fid,
                    fileName: ''
                }
                this.IPAList.push(sFile)
            }
            kycAPI.saveIPAFile({list: this.IPAList}).then(response => {
                if(response?.code == 0){
                    // this.isIPASuccess = true
                }
            })
        }
    }
})