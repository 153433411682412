import { RequestParams, ContentType, Method, getHttpClient } from '@/http'
import { PublicKeyResponse, WebSokectConfig } from '@/model/configObject'
import { JSEncrypt } from 'jsencrypt'
import { RootObject } from '@/model/rootObject'
const voca = require('voca');

const MVGX_DOMAIN_NAME = "mvgx.com"
const MCE_DOMAIN_NAME = "mce.sg"
const LOACL_DOMAIN_NAME = "localhost"


const PUBLIC_KEY = "ras-public-key"
const WEB_SOCKET_CONFIG = "web-socket-config"

function loadPublicKey() {
    const request = getHttpClient(false).request<PublicKeyResponse>('auth/rsa/public-key/get', Method.GET, {}, ContentType.json)
    request.then(response => {
        if (response) {
            sessionStorage[PUBLIC_KEY] = response.publicKey
        }
    })
}

function loadWebSocketConfig() {
    const request = getHttpClient(false).request<RootObject<WebSokectConfig>>('config/ws/get', Method.GET, {}, ContentType.json)
    request.then(response => {
        if (response) {
            const webSocketConfig = response.data
            const json = JSON.stringify(webSocketConfig)
            sessionStorage[WEB_SOCKET_CONFIG] = json
        }
    })
}


export function getPublicKey() {
    return sessionStorage[PUBLIC_KEY]
}

export function publicKey(request: any) {
    console.log('request', request)
    const publicKey = getPublicKey()
    // 新建JSEncrypt对象
    const encryptor = new JSEncrypt();
    // 设置公钥
    encryptor.setPublicKey(publicKey);
    const encryptedPassword = encryptor.encrypt(request.password);
    const encryptedOldPassword = encryptor.encrypt(request.oldPassword);
    const encryptedNewPassword = encryptor.encrypt(request.newPassword);
    if (encryptedPassword) {
        request.password = encryptedPassword;
        return request.password
    }
    if (encryptedOldPassword || encryptedNewPassword) {
        request.oldPassword = encryptedOldPassword;
        request.newPassword = encryptedNewPassword;
        return request.oldPassword, request.newPassword
    }
    return null
}


/*获取websocket配置*/
export function getWebSocketConfig() {

    const hostname = voca.lowerCase(window.location.hostname);
    console.log("hostname", hostname)

    const json = sessionStorage[WEB_SOCKET_CONFIG]
    if (json) {
        const webSocketConfig = JSON.parse(json)
        return webSocketConfig
    }
    else {
        // to do

        const applicationId = "aff46bf8-bba9-4c79-a1cd-894b2c268803"
        if (voca.indexOf(hostname, MVGX_DOMAIN_NAME) > -1
            || voca.indexOf(hostname, MCE_DOMAIN_NAME) > -1) {
            if (voca.indexOf(hostname, "test") > -1) {
                const webSocketConfig = { url: "wss://api-ws.test.mvgx.com/ws", applicationId: applicationId }
                console.log("wss test", webSocketConfig)
                return webSocketConfig;
            } else if (voca.indexOf(hostname, "dev") > -1) {
                const webSocketConfig = { url: "wss://api-ws.ams-dev.mvgx.com/ws", applicationId: applicationId }
                console.log("wss dev", webSocketConfig)
                return webSocketConfig;
            } else {
                const webSocketConfig = { url: "wss://api-ws.mvgx.com/ws", applicationId: applicationId }
                console.log("wss prod", webSocketConfig)
                return webSocketConfig;
            }
        } else if (voca.indexOf(hostname, LOACL_DOMAIN_NAME) > -1) {
            // const webSocketConfig = { url: "ws://localhost:9401/ws" }
            const webSocketConfig = { url: " wss://api-ws.test.mvgx.com/ws", applicationId: applicationId }
            console.log("wss local", webSocketConfig)
            return webSocketConfig
        } else {
            let wssUrl = voca.replace(hostname, 'www', 'api-ws');
            wssUrl = voca.replace(wssUrl, 'trader-site-v2', 'api-ws');

            const webSocketConfig = { url: "ws://" + wssUrl + "/ws", applicationId: applicationId }
            console.log("wss other", webSocketConfig)
            return webSocketConfig
        }
    }
}

export function getCdnConfig() {
    const config = {

        getCurrencyUrl: (currency: string) => {
            const url = "https://d1850j7ztm58xd.cloudfront.net/trader-site-v3-static/images/currency/" + currency + ".png";
            return url
        }
    }
    return config
}

export function getPageConfig() {
    const config = {
        pageNumber: 1,
        pageSize: 10,
        total: -1,
        pageCount: -1
    }
    return config
}

//判断域名
export function isMvgx() {
    const hostname = voca.lowerCase(window.location.hostname);
    if ( voca.indexOf(hostname, MVGX_DOMAIN_NAME) > -1 ){
        return true
    }
    return false
}
/*加载配置*/
function load() {
    console.log("load config")
    loadPublicKey()
    loadWebSocketConfig()
}



function getSiteType() {

    return "MCE"
}
export default { load }