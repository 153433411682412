import jwtDecode, { JwtPayload } from "jwt-decode";
import { getToken } from "@/http/auth";
import { func } from "vue-types";

class UserPayload implements JwtPayload {
    iss?: string
    sub?: string
    pid?: string
    aud?: string[] | string
    exp?: number
    nbf?: number
    iat?: number
    jti?: string
    rol?: string[] | string
    t2fa?: string | number
    fullName?: string
}


/* Role 枚举 */
enum Role {
    markets = "M",
    trades = "T",
    investments = "I",
    abn = "I-A",
    miniBitcoin = "I-M",
    bitcoinIndex = "I-B",
    nft = "N",
    otc = "O",
    overviewPortfolio = "O-P",
    overviewHistory = "O-H",
    overviewStatement = "O-R",
    verification = "O-U",
    default = "L-A",
    defaultMiniBitcoin = "L-B",
    kycOrSub = "U-S",
    quickAccess = "Q-A"
}


function getUser(): UserPayload | null {
    const token = getToken()
    if (!token) {
        return null
    }
    const payload = jwtDecode<UserPayload>(token)
    // console.log('payload',payload)

    return payload
}


function hasRole(role: string): boolean {
    const user = getUser()
    if (user?.rol) {
        return user?.rol.indexOf(role) > -1
    }
    return false
}

function hasRoleInLogin(role: string): boolean {
    const user = getUser()
    if (user == null) {
        return true;
    } else {
        if (user.rol) {
            return user.rol.indexOf(role) > -1
        }
    }
    return false
}

export { Role, getUser, hasRole, hasRoleInLogin }