import WS from "@/ws"
import { RequestParams, ContentType, Method, getHttpClient } from '@/http'
import { Order, CancelOrder, UpdateOrder } from '@/model/orderObject';
import { RootObject, PageRootObject } from '@/model/rootObject'



function makeOrder(request: Order) {
    return getHttpClient(true).request<RootObject<String>>('fix/new/order/single', Method.POST, request, ContentType.json)
}

function cancelOrder(request: CancelOrder) {
    return getHttpClient(true).request<RootObject<String>>('fix/order/cancel/request', Method.POST, request, ContentType.json)
}

function updateOrder(request: UpdateOrder) {
    return getHttpClient(true).request<RootObject<String>>('fix/order/cancel/replace/request', Method.POST, request, ContentType.json)
}

function subOrderReport(senderSubId: string, callback: (data: any) => void) {
    WS.subscribe("/orders/report", { senderSubId: senderSubId }, callback)
}


export default { makeOrder, cancelOrder, updateOrder, subOrderReport }