import { defineStore } from 'pinia'
import portfolioApi from '@/api/portfolio'


export const useCurrencyStore = defineStore('currency', {
    state: () => {
        return {
            selected: "USD",
            currencyAccountTypeMap: new Map<string, number>(),
        }
    },
    getters: {

    },
    actions: {
        change(newCurrency: string) {
            this.selected = newCurrency
        },
        loadCurrencyAccountTypeMap() {

            if (this.currencyAccountTypeMap.size == 0) {
                portfolioApi.getAccountTypeByCurrency().then(response => {
                    if (response) {
                        if (response.code == 0) {
                            response.data.forEach((item: any) => {
                                this.currencyAccountTypeMap.set(item.currency, item.accountType)
                            })
                        }
                    }
                });
            }

        }


    }
})