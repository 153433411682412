import WS from "@/ws"
import { ContentType, Method, getHttpClient } from '@/http'
import { RootObject } from '@/model/rootObject'
import {
    Config, InvestScheme, AvailableCap, docusignUserRequest, docusignUserResponse, docusignRedirectRequest, getParticipantResponse, saveSubscriptionRecordRequest, getSubscriptionRecordListResponse,
    subscriptionRecordCancelRequest, saveRedemptionRecordResquest, subscriptionRecordListRequest, redemptionRecordListRequest
} from '@/model/miniBtcObject'



function getConfig() {
    return getHttpClient(true).request<RootObject<Config>>('mini-btc/configuration/get', Method.GET, {}, ContentType.json)
}


function getInvestScheme() {
    return getHttpClient(true).request<RootObject<InvestScheme>>('mini-btc/invest-scheme/get', Method.GET, {}, ContentType.json)
}


function getDocusignStatus(request?:{type:string}) {
    return getHttpClient(true).request<RootObject<string>>('docusign/status/get', Method.GET, request || {}, ContentType.json)
}

function getCalculatePercentage() {
    return getHttpClient(true).request<RootObject<AvailableCap>>('mini-btc/calculate/percentage', Method.GET, {}, ContentType.json)
}

function getDocusignUser(request: docusignUserRequest) {
    return getHttpClient(false).request<RootObject<docusignUserResponse>>('docusign/user/get', Method.GET, request, ContentType.json)
}

function getParticipant() {
    return getHttpClient(true).request<RootObject<getParticipantResponse>>('participant/get', Method.GET, {}, ContentType.json)
}

function saveDocusignRedirect(request: docusignRedirectRequest) {
    return getHttpClient(true).request<RootObject<docusignUserResponse>>('docusign/redirect/url/get', Method.POST, request, ContentType.json)
}

function saveSubscriptionRecord(request: saveSubscriptionRecordRequest) {
    return getHttpClient(true).request<RootObject<docusignUserResponse>>('mini-btc/subscription-record/save', Method.POST, request, ContentType.json)
}

function getSubscriptionRecordList(request: subscriptionRecordListRequest) {
    const paginationConfig = {
        headers: {
            pageSize: request.pagination.pageSize,
            pageNumber: request.pagination.pageNumber
        }
    }
    return getHttpClient(true).request<getSubscriptionRecordListResponse>('mini-btc/subscription-record/get-by-account', Method.POST, request, ContentType.json, paginationConfig)
}

function subscriptionRecordCancel(request: subscriptionRecordCancelRequest) {
    return getHttpClient(true).request<RootObject<getSubscriptionRecordListResponse>>('mini-btc/subscription-record/cancel', Method.POST, request, ContentType.json)
}

function saveRedemptionRecord(request: saveRedemptionRecordResquest) {
    return getHttpClient(true).request<RootObject<docusignUserResponse>>('mini-btc/redemption-record/save', Method.POST, request, ContentType.json)
}

function getRedemptionRecordList(request: redemptionRecordListRequest) {
    const paginationConfig = {
        headers: {
            pageSize: request.pagination.pageSize,
            pageNumber: request.pagination.pageNumber
        }
    }
    return getHttpClient(true).request<getSubscriptionRecordListResponse>('mini-btc/redemption-record/get-by-account', Method.POST, request, ContentType.json, paginationConfig)
}

function redemptionRecordCancel(request: subscriptionRecordCancelRequest) {
    return getHttpClient(true).request<RootObject<getSubscriptionRecordListResponse>>('mini-btc/redemption-record/cancel', Method.POST, request, ContentType.json)
}

function subIndexKline(symbol: string, callback: (data: any) => void) {
    WS.subscribe("/index/price/Kline", { symbol: symbol, period: "1day" }, callback)
}

function getMmnConfig(request: any) {
    return getHttpClient(true).request<any>('/sysConfig/mmnConfig', Method.POST, request, ContentType.json)
}

export default {
    getConfig, getInvestScheme, getDocusignStatus, getCalculatePercentage, getDocusignUser, getParticipant, saveDocusignRedirect, saveSubscriptionRecord, getSubscriptionRecordList, subscriptionRecordCancel,
    saveRedemptionRecord, getRedemptionRecordList, redemptionRecordCancel, subIndexKline, getMmnConfig
}