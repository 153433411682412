import { defineStore } from 'pinia'

export const useLoginStore = defineStore('login', {
    state: () => {
        return {
            loginForm:{
                username:'',
                password:'',
                sitekey:'',
            },
            twoFaForm:{
                two2faCode:'',
            },
            signUpRules:{
                usernameShow:false,
                usernameMyregShow:false,
                passwordShow:false
            },
            signUpForm:{
                email:'',
                securityCode:'',
                kycAdmin: null,
                // password:'',
                // confirm:'',
                // agreement:false
            },
            signUpInformationForm:{
                firstName: '',
                lastName: '',
                password:'',
                confirm:'',
                referralCode:'',
                agreement:false
            },
            signUpShow:false,
            checkEmailTips:'',
            recaptchaShow:false,
            signUpPassword:{
                typeOne: false,
                typeTwo: false,
                typeThree: false
            },
            forgotPasswordForm:{
                email:'',
                code:'',
                password:'',
                confirm:'',
                twoCode:''
            },
            forgotPassword:{
                typeOne: false,
                typeTwo: false,
                typeThree: false
            },
            expireShow:false,
            date:'',
            isExpire:'',

            sub:'',
            isAdmin:'',
            code:'',
            isClone:'',
            participantId:'',
            formId:'',
            kycType:'',
            loadFirst:''
        }
    },
    getters: {

    },
    actions:{

    }
    
})