import { isMvgx } from '@/api/config';
import { defineStore } from 'pinia'
import marketApi from '@/api/market'
import portfolioApi from '@/api/portfolio'
import { useCurrencyStore } from './currency';
import { MarketItem, FavoritesItem } from '@/model/marketObject';
import { MarketCurrencyItem, MarketTradeItem, DepthResponse } from '@/model/marketObject'
import currency from 'currency.js';
const voca = require('voca');



export const useMarketStore = defineStore('market', {
    state: () => {
        return {
            tradingHotList: [{ currency: "BTC", name: "Bitcoin", lastPrice: 23235.74, change: -1.65 }, { currency: "ETH", name: "Ethereum", lastPrice: 1678.51, change: 1.86 }],
            allMarketItemList: [] as MarketItem[],
            marketItemList: [] as MarketItem[],
            favoritesItemList: [] as MarketItem[],
            currencyMap: new Map<string, MarketCurrencyItem>(),
            favoritesMap: new Map<string, FavoritesItem>(),
            isLoadFavorites: false,
            recentTradeList: [] as MarketTradeItem[],
            lastPrice: { price: 0, aggressorVerb: 1 },
            depthResponse: {} as DepthResponse,
            bestPrice: {
                buy: 0,
                sell: 0
            },
            subRecentTradeSymbol: "",
            subDepthSymbol: "",
            klineList: [] as any[],
            index: {
                change: 0.0004,
                currentPrice: 19844.58,
                high: 20175.72,
                low: 19663.21,
                priceUp: true
            }
        }
    },
    getters: {
        getTradingHotList: (state) => {
            return state.tradingHotList
        }
    },
    actions: {
        subTicker() {
            marketApi.subTicker((data) => {
                // 选择当前的币种
                const currencyStore = useCurrencyStore()
                const currency = currencyStore.selected

                for (var i = 0; i < this.tradingHotList.length; i++) {
                    const tradingHot = this.tradingHotList[i]
                    const symbol = tradingHot.currency + "/" + currency
                    data.response.forEach((item: { symbol: string; lastPx: number; pxChangePercent: number }) => {
                        if (item.symbol === symbol) {
                            tradingHot.lastPrice = item.lastPx
                            if (item.pxChangePercent) {
                                tradingHot.change = item.pxChangePercent
                            } else {
                                tradingHot.change = 0
                            }
                        }
                    })
                }


                // 加载市场货币数据数据
                if (this.currencyMap.size == 0) {
                    marketApi.getMarketItemList(currency).then(response => {
                        if (response) {
                            if (response.code == 0) {
                                response.data.forEach((item: MarketCurrencyItem) => {
                                    this.currencyMap.set(item.currencySymbol, item)
                                })
                            }
                        }
                    })
                }

                // 加载账号类型数据
                currencyStore.loadCurrencyAccountTypeMap()


                // 收藏

                if (this.isLoadFavorites == false) {
                    marketApi.getFavoritesList(currency).then(response => {
                        if (response) {
                            if (response.code == 0) {
                                response.data.forEach((item: FavoritesItem) => {
                                    this.favoritesMap.set(item.quoteCurrencyId + "/" + item.baseCurrencyId, item)
                                    this.isLoadFavorites = true;
                                })
                            }
                        }
                    })
                }

                this.marketItemList = [] as MarketItem[]
                this.favoritesItemList = [] as MarketItem[]
                this.allMarketItemList = [] as MarketItem[]

                // const list = data.response.filter((item: { symbol: string; }) => item.symbol.split("/")[1] === currency)
                data.response.forEach((item: { symbol: string; lastPx: number; pxChangePercent: number, highPx: number, lowPx: number, vol: number, amount: number, boardIndex: number | null }) => {


                    // 检查在currency 配置中是否有该基础货币，如果没有暂时不在市场列表中显示
                    const quoteCurrency = item.symbol.split("/")[1]
                    const baseCurrency = item.symbol.split("/")[0]
                    const currencyItem = this.currencyMap.get(baseCurrency)


                    // symbol: BTC/USD currency: USD quoteCurrency: USD baseCurrency: BTC

                    if (currencyItem) {

                        const marketItem = {
                            symbol: item.symbol,
                            baseCurrency: baseCurrency,
                            baseCurrencyId: currencyItem.id,
                            quoteCurrency: quoteCurrency,
                            quoteCurrencyId: quoteCurrency,
                            lastPrice: item.lastPx,
                            change: item.pxChangePercent == null ? 0 : item.pxChangePercent,
                            high: item.highPx == null ? item.lastPx : item.highPx,
                            low: item.lowPx == null ? item.lastPx : item.lowPx,
                            amount: item.amount == null ? 0 : Number(item.amount.toFixed(2)),
                            volume: item.vol == null ? item.vol : item.vol,
                            isFavorite: false,
                            boardIndex: item.boardIndex,
                            accountType: 0
                        }

                        const accountType = currencyStore.currencyAccountTypeMap.get(marketItem.baseCurrencyId)
                        if (accountType) {
                            marketItem.accountType = accountType
                        }


                        // 废弃字段 custodyFeeRate 判断是否显示币种实现在市场
                        if (currencyItem.custodyFeeRate != 999) {
                            if (isMvgx()) {
                                if (marketItem.accountType == 4) {
                                    if (voca.trim(quoteCurrency) == voca.trim(currency)) {
                                        this.marketItemList.push(marketItem)
                                    }
                                    this.allMarketItemList.push(marketItem)
                                }
                            } else {
                                if (voca.trim(quoteCurrency) == voca.trim(currency)) {
                                    this.marketItemList.push(marketItem)
                                }
                                this.allMarketItemList.push(marketItem)
                            }

                            // 删除HBIS币种
                            this.allMarketItemList = this.allMarketItemList.filter(function(item) {
                                return item.baseCurrency !== "HBIS001"
                            });
                            this.marketItemList = this.marketItemList.filter(function(item) {
                                return item.baseCurrency !== "HBIS001"
                            });

                            const favoritesKey = marketItem.baseCurrencyId + "/" + marketItem.quoteCurrencyId
                            const favoritesItem = this.favoritesMap.get(favoritesKey)
                            if (favoritesItem) {
                                marketItem.isFavorite = true
                                if (isMvgx()) {
                                    if (marketItem.accountType == 4) {
                                        this.favoritesItemList.push(marketItem)
                                    }
                                } else {
                                    this.favoritesItemList.push(marketItem)
                                }
                            }
                        }



                    }

                })

            })
        },
        subRecentTrade(symbol: string) {
            // 不重新订阅
            if (this.subRecentTradeSymbol == symbol) {
                return;
            }
            // 取消订阅， 如果一起已经订阅
            marketApi.unsubRecentTrade()
            // 清空现在的RecentTrader
            this.recentTradeList = [] as MarketTradeItem[];
            this.subRecentTradeSymbol = symbol

            marketApi.subRecentTrade(symbol, (data: any) => {
                this.recentTradeList = [] as MarketTradeItem[];
                if (this.subDepthSymbol == data.response.symbol) {
                    data.response.marketTradeSubs.forEach((item:
                        {
                            lastPx: number; lastQty: number; time: Date, tradeID: string, aggressorVerb: number
                        }) => {

                        const marketTradeItem: MarketTradeItem = {
                            lastPx: item.lastPx,
                            lastPxVirtualPoint: this.getVirtualPoint(item.lastPx, 2),
                            lastQty: item.lastQty,
                            lastQtyVirtualPoint: this.getVirtualPoint(item.lastQty, 8),
                            time: item.time,
                            tradeID: item.tradeID,
                            aggressorVerb: item.aggressorVerb
                        }

                        this.recentTradeList.push(marketTradeItem)
                    })

                    if (this.recentTradeList.length > 0) {
                        this.lastPrice.price = this.recentTradeList[0].lastPx
                        this.lastPrice.aggressorVerb = this.recentTradeList[0].aggressorVerb
                    } else {
                        this.lastPrice.price = 0
                        this.lastPrice.aggressorVerb = 1
                    }
                }

            })
        },
        subDepth(symbol: string) {
            // 不重新订阅
            if (this.subDepthSymbol == symbol) {
                return;
            }
            // 取消订阅， 如果一起已经订阅 
            marketApi.unsubDepth()
            this.depthResponse.asks = []
            this.depthResponse.bids = []

            this.bestPrice.buy = 0
            this.bestPrice.sell = 0
            this.subDepthSymbol = symbol
            marketApi.subDepth(symbol, (data: any) => {

                if (this.subDepthSymbol == data.response.symbol) {
                    this.depthResponse = data.response
                    this.depthResponse.asks.forEach((item) => {
                        item.priceVirtualPoint = this.getVirtualPoint(item.price, 2);
                        item.quantityVirtualPoint = this.getVirtualPoint(item.quantity, 8);
                        const total = item.total.toFixed(2)
                        item.total = Number(total)
                        item.totalVirtualPoint = this.getVirtualPoint(item.total, 2);
                    });


                    this.depthResponse.bids.forEach((item) => {
                        item.priceVirtualPoint = this.getVirtualPoint(item.price, 2);
                        item.quantityVirtualPoint = this.getVirtualPoint(item.quantity, 8);

                        const total = item.total.toFixed(2)
                        item.total = Number(total)
                        item.totalVirtualPoint = this.getVirtualPoint(item.total, 2);
                    });


                    // 计算最佳买卖价格
                    if (this.depthResponse.asks.length > 0) {
                        this.bestPrice.buy = this.depthResponse.asks[0].price
                    }
                    if (this.depthResponse.bids.length > 0) {
                        this.bestPrice.sell = this.depthResponse.bids[0].price
                    }

                }


            });
        },
        subIndexPrice(symbol?:string) {
            marketApi.subIndexPrice(symbol || "BTC/USD", (data: any) => {
                this.index = data.response.data
            });

        },
        getVirtualPoint(pq: number, precision: number) {
            const spq = String(pq)
            const pqCrrency = currency(spq, { separator: "", symbol: "", precision: precision }).format()
            const result = pqCrrency.replace(spq, "")
            return result
        },
        removeMarketItem(baseCurrencyId: string, quoteCurrencyId: string) {
            const key = baseCurrencyId + "/" + quoteCurrencyId

            this.allMarketItemList.forEach((item, index) => {
                if (item.baseCurrencyId == baseCurrencyId && item.quoteCurrencyId == quoteCurrencyId) {
                    item.isFavorite = false
                }
            })
            this.favoritesMap.delete(baseCurrencyId + "/" + quoteCurrencyId)

            this.favoritesItemList.forEach((item, index) => {
                if (item.baseCurrencyId == baseCurrencyId && item.quoteCurrencyId == quoteCurrencyId) {
                    this.favoritesItemList.splice(index, 1)
                }
            })
        },
        addMarketItem(baseCurrencyId: string, quoteCurrencyId: string) {
            const key = baseCurrencyId + "/" + quoteCurrencyId

            this.allMarketItemList.forEach((item, index) => {
                if (item.baseCurrencyId == baseCurrencyId && item.quoteCurrencyId == quoteCurrencyId) {
                    item.isFavorite = true
                }
            })

            const favoritesItem = { baseCurrencyId: quoteCurrencyId, quoteCurrencyId: baseCurrencyId }
            this.favoritesMap.set(favoritesItem.quoteCurrencyId + "/" + favoritesItem.baseCurrencyId, favoritesItem)
        },
        subKline(symbol: string) {
            marketApi.unsubKline(symbol)
            marketApi.subKline(symbol, (data: any) => {
                console.log('99900=====',data)
            });
        }
    }

})