import WS from "@/ws"
import { ChartRequest, FavoritesRequest } from '@/model/marketObject'
import { RootObject } from '@/model/rootObject'
import { RequestParams, ContentType, Method, getHttpClient } from '@/http'
import { MarketCurrencyItem, FavoritesItem, TradingTime } from '@/model/marketObject'
import { getUser, hasRole } from "@/api/user"

function subTicker(callback: (data: any) => void) {
    WS.subscribe("/markets/ticker", {}, callback)
}

function subIndexBasePrice(symbol: string, callback: (data: any) => void) {
    WS.subscribe("/index/priceKline/upOrDown", { symbol: symbol }, callback)
}
function unSubIndexBasePrice(symbol: string) {
    WS.unsubscribe("/index/priceKline/upOrDown", { symbol: symbol })
}
function getChartData(request: ChartRequest) {
    return getHttpClient(false).request<RootObject<number[]>>('market/echarts', Method.POST, request, ContentType.json)
}


function saveFavorites(request: FavoritesRequest) {
    return getHttpClient(true).request<RootObject<any>>('user/favorites/save', Method.POST, request, ContentType.json)
}

function deleteFavorites(request: FavoritesRequest) {
    return getHttpClient(true).request<RootObject<any>>('user/favorites/delete', Method.POST, request, ContentType.json)
}

function getFavoritesList(currency: string) {
    const user = getUser();
    if (user) {

        if (user.t2fa == 0) {
            const request = {};
            return getHttpClient(true).request<RootObject<FavoritesItem[]>>('user/favorites/list', Method.POST, request, ContentType.json)
        } else {
            if (hasRole('2FA')) {
                const request = {};
                return getHttpClient(true).request<RootObject<FavoritesItem[]>>('user/favorites/list', Method.POST, request, ContentType.json)
            } else {
                return new Promise<RootObject<FavoritesItem[]>>((resolve, reject) => {
                    resolve({ code: 0, data: [] })
                })
            }
        }


    } else {
        return new Promise<RootObject<FavoritesItem[]>>((resolve, reject) => {
            resolve({ code: 0, data: [] })
        })
    }
}


function getMarketItemList(currency: string) {
    const request = { fiatCurrency: currency };
    return getHttpClient(false).request<RootObject<MarketCurrencyItem[]>>('market/all/list', Method.POST, request, ContentType.json)
}

function subRecentTrade(symbol: string, callback: (data: any) => void) {
    WS.subscribe("/markets/recent-trade", { symbol: symbol }, callback)
}

function unsubRecentTrade() {
    WS.unsubscribe("/markets/recent-trade", {})
}

function subDepth(symbol: string, callback: (data: any) => void) {
    WS.subscribe("/markets/depth", { symbol: symbol }, callback)
}

function unsubDepth() {
    WS.unsubscribe("/markets/depth", {})
}

function subIndexPrice(symbol: string, callback: (data: any) => void) {
    WS.subscribe("/index/priceKline/upOrDown", { symbol: symbol }, callback)
}

function subKline(symbol: string, callback: (data: any) => void) {
    WS.subscribe("/markets/kline", { symbol: symbol, period: "1day" }, callback)
}

function unsubKline(symbol: string) {
    WS.unsubscribe("/markets/kline", {})
}
/**
 * desc:获取交易的开盘时间和收盘时间
 * @returns 
 */
function getTradingTime() {
    return getHttpClient(true).request<RootObject<TradingTime>>('trading-time/get', Method.GET, {}, ContentType.json)
}
export default {
    subTicker, subIndexBasePrice, subRecentTrade, unsubRecentTrade, subDepth, unsubDepth, getChartData, saveFavorites,
    deleteFavorites, getMarketItemList, subIndexPrice, getFavoritesList, subKline, unsubKline, getTradingTime,unSubIndexBasePrice
}