import { defineStore } from 'pinia'
import { string } from 'vue-types'
import { Orderbook, OrderItem, TradeItem, InvestmentOrderItem } from '@/model/orderObject'
import { getSubscriptionRecordListItem } from '@/model/miniBtcObject'
import otcAPI from "@/api/otc";
import orderAPI from "@/api/order";
import miniBtcApi from '@/api/miniBtc'
import { getPageConfig } from '@/api/config'
import { useOtcStore } from '@/stores/otc'

const pageConfig = getPageConfig()

export const useOrderStore = defineStore('order', {
    state: () => {
        return {
            orderAllFind: {
                type: null,
                dateRange: [],
                pagination: pageConfig
            },
            otcAllFind: {
                type: null,
                dateRange: [],
                pagination: pageConfig
            },
            investmentAllFind: {
                type: 'Subscription',
                dateRange: [],
                pagination: pageConfig,
                subscrible: {
                    pagination: pageConfig
                },
                redeem: {
                    pagination: pageConfig
                },
            },

            orderbookList: [] as Orderbook[],
            openOrderList: [] as OrderItem[],
            historyOrderList: [] as OrderItem[],
            openOrderListForTrade: [] as OrderItem[],
            historyOrderListForTrade: [] as OrderItem[],
            tradeList: [] as TradeItem[],
            //investmentList: [] as InvestmentOrderItem[],
            subscriptionRecordList: [{} as getSubscriptionRecordListItem],
            redemptionRecordList: [{} as getSubscriptionRecordListItem],
            orderSelected: 1,
            spotSelected: 1,
            selectOrdedrItem: {} as OrderItem, 
        }
    },
    getters: {
        allFind: (state) => {
            switch (state.orderSelected) {
                case 1:
                    return state.orderAllFind
                case 2:
                    return state.investmentAllFind
                case 3:
                    return state.otcAllFind;
            }
            return state.orderAllFind
        }
    },
    actions: {
        loadOtcOrderbookList() {
            otcAPI.getOrderbookList().then(response => {
                if (response) {
                    if (response.code == 0) {
                        this.orderbookList = []
                        this.orderbookList.push({ id: null, entityIndex: null, secCode: "All" })

                        // response.data 循环加入到orderbookList
                        response.data.forEach((item: Orderbook) => {
                            this.orderbookList.push({ id: item.id, entityIndex: item.id, secCode: item.secCode })
                        })
                    }
                }
            })
        },
        loadSpotOrderbookList() {
            orderAPI.getOrderbookList().then(response => {
                if (response) {
                    if (response.code == 0) {
                        this.orderbookList = []
                        this.orderbookList.push({ id: null, entityIndex: null, secCode: "All" })

                        // response.data 循环加入到orderbookList
                        response.data.forEach((item: Orderbook) => {
                            this.orderbookList.push({ id: item.entityIndex, entityIndex: item.entityIndex, secCode: item.secCode })
                        })
                    }
                }
            })
        },
        loadInvestmentOrderbookList() {
            this.orderbookList = []
            //this.orderbookList.push({ id: null, entityIndex: null, secCode: "All" })
            this.orderbookList.push({ id: "Subscription", entityIndex: null, secCode: "Subscription" })
            this.orderbookList.push({ id: "Redemption", entityIndex: null, secCode: "Redemption" })
        },
        loadOrderbookList() {
            switch (this.orderSelected) {
                case 1:
                    this.loadSpotOrderbookList()
                    break;
                case 2:
                    this.loadInvestmentOrderbookList()
                    break;
                case 3:
                    this.loadOtcOrderbookList()
                    break;
            }

        },
        gotoSpot() {
            this.orderSelected = 1
            this.spotSelected = 1
            this.loadOrderbookList()
            this.loadOrderList()
        },
        gotoSpotHistory() {
            this.orderSelected = 1
            this.spotSelected = 2
            this.loadOrderbookList()
            this.loadOrderList()
        },
        gotoInvestment() {
            this.orderSelected = 2
            this.loadOrderbookList()
            this.loadOrderList()
        },
        gotoOTC() {
            this.orderSelected = 3
            this.loadOrderbookList()
            this.loadOrderList()
        },
        gotoSpotTrade() {
            this.orderSelected = 1
            this.spotSelected = 3
        },
        loadOtcOrderList() {
            const searchCondition = {
                symbol: this.otcAllFind.type,
                startDate: "",
                endDate: "",
                pagination: this.otcAllFind.pagination
            }
            if (this.otcAllFind.dateRange != null && this.otcAllFind.dateRange.length == 2) {
                searchCondition.startDate = this.otcAllFind.dateRange[0]
                searchCondition.endDate = this.otcAllFind.dateRange[1]
            }
            otcAPI.getOrderList(searchCondition).then(response => {
                if (response) {
                    if (response.code == 0) {
                        const otcStore = useOtcStore()
                        otcStore.listType = "allRecords"
                        otcStore.list = response.data
                        this.otcAllFind.pagination = response.pagination

                    }
                }
            })
        },
        loadSpotOrderList() {
            const searchCondition = {
                orderBookIndex: this.orderAllFind.type,
                startDate: "",
                endDate: "",
                searchOpen: true,
                pagination: this.orderAllFind.pagination
            }
            if (this.orderAllFind.dateRange != null && this.orderAllFind.dateRange.length == 2) {
                searchCondition.startDate = this.orderAllFind.dateRange[0]
                searchCondition.endDate = this.orderAllFind.dateRange[1]
            }

            if (this.spotSelected == 2) {
                searchCondition.searchOpen = false
            }

            orderAPI.getOrderList(searchCondition).then(response => {
                if (response) {
                    if (response.code == 0) {
                        if (this.spotSelected == 1) {
                            this.openOrderList = response.data
                        }
                        if (this.spotSelected == 2) {
                            this.historyOrderList = response.data
                        }
                        this.orderAllFind.pagination = response.pagination
                    }
                }
            })
        },

        loadTradeOrderList() {
            const searchCondition = {
                orderBookIndex: this.orderAllFind.type,
                startDate: "",
                endDate: "",
                pagination: this.orderAllFind.pagination
            }
            if (this.orderAllFind.dateRange != null && this.orderAllFind.dateRange.length == 2) {
                searchCondition.startDate = this.orderAllFind.dateRange[0]
                searchCondition.endDate = this.orderAllFind.dateRange[1]
            }
            orderAPI.getTradeList(searchCondition).then(response => {
                if (response) {
                    if (response.code == 0) {
                        this.tradeList = response.data
                        this.orderAllFind.pagination = response.pagination
                    }
                }
            })
        },
        // loadInvestmentOrederList() {
        //     const searchCondition = {
        //         type: this.investmentAllFind.type,
        //         startDate: "",
        //         endDate: "",
        //         pagination: this.investmentAllFind.pagination
        //     }
        //     if (this.investmentAllFind.dateRange != null && this.investmentAllFind.dateRange.length == 2) {
        //         searchCondition.startDate = this.investmentAllFind.dateRange[0]
        //         searchCondition.endDate = this.investmentAllFind.dateRange[1]
        //     }
        //     orderAPI.getInvestmentList(searchCondition).then(response => {
        //         if (response) {
        //             if (response.code == 0) {
        //                 this.investmentList = response.data
        //                 this.investmentAllFind.pagination = response.pagination
        //             }
        //         }
        //     })
        // },
        loadSubscriptionRecordList() {
            let request = {  
                startDate: '',
                endDate: '',
                pagination: this.investmentAllFind.subscrible.pagination
            }
            if (this.investmentAllFind.dateRange != null && this.investmentAllFind.dateRange.length == 2) {
                request.startDate = this.investmentAllFind.dateRange[0]
                request.endDate = this.investmentAllFind.dateRange[1]
            }
            miniBtcApi.getSubscriptionRecordList(request).then(response => {
                if (response) {
                    this.subscriptionRecordList = response.data
                    this.investmentAllFind.subscrible.pagination = response.pagination
                }
            })
        },
        loadRedemptionRecordList() {
            let request = {  
                startDate: '',
                endDate: '',
                pagination: this.investmentAllFind.redeem.pagination
            }
            if (this.investmentAllFind.dateRange != null && this.investmentAllFind.dateRange.length == 2) {
                request.startDate = this.investmentAllFind.dateRange[0]
                request.endDate = this.investmentAllFind.dateRange[1]
            }
            miniBtcApi.getRedemptionRecordList(request).then(response => {
                if (response) {
                    this.redemptionRecordList = response.data
                    this.investmentAllFind.redeem.pagination = response.pagination
                }
            })
        },
        loadOrderList() {
            switch (this.orderSelected) {
                case 1:
                    if (this.spotSelected == 1 || this.spotSelected == 2) {
                        this.loadSpotOrderList();
                    } else if (this.spotSelected == 3) {
                        this.loadTradeOrderList();
                    }
                    break;
                case 2:
                    if( this.investmentAllFind.type == 'Subscription') {
                        this.loadSubscriptionRecordList()
                    } else if ( this.investmentAllFind.type == 'Redemption'){
                        this.loadRedemptionRecordList()
                    }
                    
                    break;
                case 3:
                    this.loadOtcOrderList();
                    break;
            }
        },
        loadOrderListForTrade(isOPen: boolean) {
            const searchCondition = {
                orderBookIndex: null,
                startDate: "",
                endDate: "",
                searchOpen: isOPen,
                pagination: getPageConfig()
            }


            orderAPI.getOrderList(searchCondition).then(response => {
                if (response) {
                    if (response.code == 0) {
                        if (isOPen) {
                            this.openOrderListForTrade = response.data
                        }
                        else {
                            this.historyOrderListForTrade = response.data
                        }
                    }
                }
            })
        }
    }
})