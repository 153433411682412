import { defineStore } from 'pinia'
import profileApi from '@/api/profile'
import { useCurrencyStore } from './currency';
import { LoginRecord, } from '@/model/profileObject'
import { getUser } from "@/api/user"

export const useProfileStore = defineStore('profile', {
    state: () => {
        return {
            loginStatus: false,
            overview: {
                email: "",
                code: "",
                tier: 0,
                accountType: "",
                volume: 0.00,
                recentLogins: "",
                ipAddress: "",
                createdTime: "",
                expirationDays: 0,
                expirationTime: "",
                tierPercent: 0,
                participantName: "",
                firstName: "",
                lastName: "",
            },
            selectedTab: 1,
            loginList: [{} as LoginRecord],
            userSecurity: {
                type2fa: 0,
            },
            googleChecked: false,
            emailChecked: false,
            google2faCode: '',
            modelShow: false,
            addressModel: false,
            contactNameModel: false,
            nameModel: false,
            descriptionModel: false,
            type: 1,
            loginPasswordForm: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
            },
            kycVeroShow: 1,
        }
    },
    getters: {
        getOverview: (state) => {
            return state.overview
        }
    },
    actions: {
        loadOverview() {
            const user = getUser();
            if (user) {
                const currencyStore = useCurrencyStore()
                console.log(currencyStore.selected)

                const request = { code: currencyStore.selected }
                profileApi.getOverview(request).then(response => {
                    if (response) {
                        this.overview = response.data
                        if(this.overview.code == 'A99999990001'){
                            this.overview.accountType = ''
                        }else{
                            if (this.overview.accountType == "A") {
                                this.overview.accountType = "Individual"
                            } else {
                                this.overview.accountType = "Business"
                            }
                        }
                        


                        if (this.overview.tier == 1) {
                            this.overview.tierPercent = 33
                        } else if (this.overview.tier == 2) {
                            this.overview.tierPercent = 66
                        } else if (this.overview.tier == 3) {
                            this.overview.tierPercent = 100
                        }
                    }
                })
            }

        },
        changeLoginT() {
            this.loginStatus = true
        },
        changeLoginF() {
            this.loginStatus = false
        },
        gotoOverview() {
            this.selectedTab = 1
        },
        gotoPersonalDetails() {
            this.selectedTab = 2
        },
        gotoKyc() {
            this.selectedTab = 3
        },
        gotoSecurity() {
            this.selectedTab = 4
        },
        gotoApiManagement() {
            this.selectedTab = 5
        },
        gotoSubAccounts() {
            this.selectedTab = 6
        },
        gotoExternalAuthorizationManagement() {
            this.selectedTab = 7
        },
        gotoPreferenceSettings() {
            this.selectedTab = 8
        },
        loadRecentLoginRecord() {
            profileApi.getRecentLoginRecord().then(response => {
                if (response) {
                    this.loginList = response.data

                    // 最近10条登录记录
                    if (this.loginList.length > 10) {
                        this.loginList = this.loginList.slice(0, 10)
                    }
                }
            })
        },

        loadUserSecurity() {
            profileApi.getUserSecurity().then(response => {
                if (response) {
                    if (response.code == 0) {
                        this.userSecurity = response.data
                        if (this.userSecurity.type2fa == 2) {
                            this.googleChecked = true
                            this.emailChecked = false
                        } else if (this.userSecurity.type2fa == 1) {
                            this.googleChecked = false
                            this.emailChecked = true
                        } else if (this.userSecurity.type2fa == 0) {
                            this.googleChecked = false
                            this.emailChecked = false
                        }
                    }
                }
            })
        },

        loadGoogle2faSecret() {
            profileApi.getGoogle2faSecret().then(response => {
                if (response) {
                    if (response.code == 0) {
                        this.google2faCode = response.data
                    }
                }
            })
        },

        loadAcceptTradingRules() {
            profileApi.acceptTradingRules().then(response => {
                if (response?.code === 0) {
                }
            })
        }

    }
})