

import currency from 'currency.js';
import { useCurrencyStore } from '@/stores/currency';
import currencyAPI from '@/api/currency';
import * as moment from 'moment';


export default {
    install: (app: any, options: any) => {
        app.config.globalProperties.$dateFormat = (value: any) => {
            return moment.default(value).format('YYYY-MM-DD');
        }

        app.config.globalProperties.$datetimeFormat = (value: any) => {
            return moment.default(value).format('YYYY-MM-DD HH:mm:ss');
        }
    }
}