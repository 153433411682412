import { defineStore } from 'pinia'
import { SerachOrderListRequest, OrderItem } from '@/model/otcObject'
import otcAPI from "@/api/otc";

export const useOtcStore = defineStore('otc', {
    state: () => {
        return {
            listType: "recentRecords",
            list: [] as OrderItem[]
        }
    },
    getters: {

    },
    actions: {
        loadRecentOrderList() {
            otcAPI.getRecentOrderList().then(response => {
                if (response) {
                    if (response.code == 0) {
                        this.listType = "recentRecords"
                        this.list = response.data
                    }
                }
            })
        }
    }
})