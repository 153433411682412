

import currency from 'currency.js';
import { useCurrencyStore } from '@/stores/currency';
import currencyAPI from '@/api/currency';

const voca = require('voca');

export default {
    install: (app: any, options: any) => {
        app.config.globalProperties.$currencyFormat = (value: any, isCurrency = false, precision = 2) => {
            if (voca.isBlank(value)) {
                return "";
            }
            if (isCurrency == true) {
                const currencyStore = useCurrencyStore();
                const symbol = currencyAPI.getSymbol(currencyStore.selected);
                const pqCrrency = currency(value, { symbol: symbol, precision: precision }).format()
                return pqCrrency
            } else {
                const pqCrrency = currency(value, { symbol: "", precision: precision }).format()
                return pqCrrency
            }
        }


        app.config.globalProperties.$currencyFormatAdvanced = (value: any, isCurrency = false, precision = 8) => {
            const result = {
                virtualPoint: "",
                realPoint: ""
            }

            if (voca.isBlank(value)) {
                return result;
            }

            let pqCrrency = ""
            if (isCurrency == true) {
                const currencyStore = useCurrencyStore();
                const symbol = currencyAPI.getSymbol(currencyStore.selected);
                pqCrrency = currency(value, { symbol: symbol, precision: 8 }).format()
            } else {
                pqCrrency = currency(value, { symbol: "", precision: 8 }).format()
            }

            for (let i = pqCrrency.length - 1; i >= 0; i--) {
                if (pqCrrency[i] != "0") {
                    break;
                }
                result.virtualPoint = result.virtualPoint + "0"
            }

            result.realPoint = pqCrrency.replace(result.virtualPoint, "")

            console.log("currencyFormatAdvanced result:", result, pqCrrency)
            return result
        }

    }
}