import { OverviewRequest, UserOverview, LoginRecord, UserSecurityResponse, userType2faRequest, Google2faSecretResponse, Google2faBindRequest, Google2BindResponse, updateUserRequest, bindContactNumberRequest, changePasswordRequest } from '@/model/profileObject'
import { RequestParams, ContentType, Method, getHttpClient } from '@/http'
import { RootObject } from '@/model/rootObject'
import { publicKey } from '@/api/config'

function getOverview(request: OverviewRequest) {
    return getHttpClient(true).request<RootObject<UserOverview>>('/user/overview/get', Method.POST, request, ContentType.json)
}

function getRecentLoginRecord() {
    return getHttpClient(true).request<RootObject<LoginRecord[]>>('user/login-record/get-recent-list', Method.GET)
}

function getUserSecurity() {
    return getHttpClient(true).request<RootObject<UserSecurityResponse>>('user/security/get', Method.GET)
}

function updateUserType2fa(request: userType2faRequest) {
    return getHttpClient(true).request<RootObject<UserSecurityResponse>>('user/type2fa/update', Method.POST, request, ContentType.json)
}


function getGoogle2faSecret() {
    return getHttpClient(true).request<Google2faSecretResponse>('user/google2faSecret/get', Method.GET)
}

function getGoogle2faBind(request: Google2faBindRequest) {
    return getHttpClient(true).request<Google2BindResponse>('user/google2fa/bind', Method.POST, request, ContentType.json)
}

function updateUser(request: updateUserRequest) {
    return getHttpClient(true).request<Google2BindResponse>('user/update', Method.POST, request, ContentType.json)
}

function bindContactNumber(request: bindContactNumberRequest) {
    return getHttpClient(true).request<Google2BindResponse>('user/contact/number/bind', Method.POST, request, ContentType.json)
}

function changePassword(request: changePasswordRequest) {
    publicKey(request)
    return getHttpClient(true).request<Google2BindResponse>('password/change', Method.POST, request, ContentType.json)
}

function acceptTradingRules() {
    return getHttpClient(true).request<Google2BindResponse>('trading-rules/accept', Method.POST, {}, ContentType.json)
}

// 新增拒收邮箱
function rejectEmailInsTo(request: any) {
    return getHttpClient(true).request<any>('/rejectEmail/insTo', Method.POST, request, ContentType.json)
}

// 删除拒收邮箱
function rejectEmailDel(request: any) {
    return getHttpClient(true).request<any>('/rejectEmail/del', Method.POST, request, ContentType.json)
}

// 邮件通知查询状态
function getRejectEmail(request: any) {
    return getHttpClient(true).request<any>('/rejectEmail/get', Method.GET, request, ContentType.json)
}



export default { getOverview, getRecentLoginRecord, getUserSecurity, updateUserType2fa, getGoogle2faSecret, getGoogle2faBind, updateUser, bindContactNumber, changePassword, acceptTradingRules, rejectEmailInsTo, rejectEmailDel, getRejectEmail }