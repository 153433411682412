import { RequestParams, ContentType, Method, getHttpClient } from '@/http'
import {
    OverviewRequest, PortfolioOverview, DepositRequiredStatus, FindRequest,
    depositCurrencyNetworkListRequest, walletAddressRequest, BalanceSearchRequest, Balance, withdrawRequiredStatus, withdrawData, WithdrawWalletAddressRequest, withdrawWalletAddressData, balanceAvailableRequest, balanceAvailableData, withdrawFeeRequest, withdrawCancelRequest,
    transferSaveRequest, withdrawSaveRequest, withdrawBankAccountData, cryptoCurrencyRequest, transferCurrencyRequest, walletAddressListData, addWalletAddressRequest,
    addWalletAddressData, getWalletAddressRequest, addBankAccountRequest, bankAccountListData, recentTransferListData, depositAddressData, CheckAddressValidRequest, SwiftCheckerRequest, WalletAddressLabelListRequest, withdrawBankAccountRequest, checkWalletRistRequest,checkWalletOwnerRequest,
    maxDecimalPlacesRequest
} from '@/model/portfolioObject'
import { CurrencyRequest } from '@/model/currencyObject'
import { RootObject, PageRootObject,riskObject } from '@/model/rootObject'
import {
    DepositData, CurrencyData, CurrencyNetworkData, WalletAddressData, DepositHistoryListData, withdrawHistoryListData, transferHistoryListData, CheckAddressValidData, SwiftCheckerData,
    WalletAddressLabelListData
} from '@/model/depositObject'
import { Billing } from '@/model/billingObject'
import WS from "@/ws"

function getOverview(request: OverviewRequest) {
    return getHttpClient(true).request<RootObject<PortfolioOverview>>('portfolio/overview/get', Method.POST, request, ContentType.json)
}

function getDepositRequiredStatus() {
    return getHttpClient(true).request<RootObject<DepositRequiredStatus>>('user/deposits/get', Method.GET)
}

function getRecentDepositList(request: CurrencyRequest) {
    return getHttpClient(true).request<RootObject<DepositData>>('recent/deposit/list', Method.POST, request, ContentType.json)
}

function getDepositCurrencyList() {
    return getHttpClient(true).request<CurrencyData>('crypto/currency/list', Method.GET)
}

function getDepositCurrencyNetworkList(request: depositCurrencyNetworkListRequest) {
    return getHttpClient(true).request<CurrencyNetworkData>('currency/network/list', Method.POST, request, ContentType.json)
}

function getWalletAddress(request: walletAddressRequest) {
    return getHttpClient(true).request<WalletAddressData>('deposit/wallet/address/get', Method.POST, request, ContentType.json)
}

function getBalanceList(request: BalanceSearchRequest) {
    return getHttpClient(true).request<RootObject<Balance[]>>('balance/get-by-account', Method.POST, request, ContentType.json)
}

function getDepositHistoryList(request: FindRequest) {
    const paginationConfig = {
        headers: {
            pageSize: request.pagination.pageSize,
            pageNumber: request.pagination.pageNumber
        }
    }

    return getHttpClient(true).request<PageRootObject<DepositHistoryListData>>('deposit/history/list', Method.POST, request, ContentType.json, paginationConfig)
}

function exportDepositHistoryList(request: FindRequest) {
    return getHttpClient(true).download('/deposit/history/export', Method.POST, request)
}

function getWithdrawHistoryList(request: FindRequest) {
    const paginationConfig = {
        headers: {
            pageSize: request.pagination.pageSize,
            pageNumber: request.pagination.pageNumber
        }
    }
    return getHttpClient(true).request<PageRootObject<withdrawHistoryListData>>('withdraw/history/list', Method.POST, request, ContentType.json, paginationConfig)
}

function exportWithdrawHistoryList(request: FindRequest) {
    return getHttpClient(true).download('/withdraw/history/export', Method.POST, request)
}


function getTransferHistoryList(request: FindRequest) {
    const paginationConfig = {
        headers: {
            pageSize: request.pagination.pageSize,
            pageNumber: request.pagination.pageNumber
        }
    }
    return getHttpClient(true).request<PageRootObject<transferHistoryListData>>('transfer/history/list', Method.POST, request, ContentType.json, paginationConfig)
}

function exportTransferHistoryList(request: FindRequest) {
    return getHttpClient(true).download('/transfer/history/export', Method.POST, request)
}


function getWithdrawStatus() {
    return getHttpClient(true).request<RootObject<withdrawRequiredStatus>>('user/withdraw/get', Method.GET)
}

function getRecentWithdrawList(request: CurrencyRequest) {
    return getHttpClient(true).request<RootObject<withdrawData>>('recent/withdraw/list', Method.POST, request, ContentType.json)
}

// function getWithdrawWalletAddress(request: WithdrawWalletAddressRequest) {
//     return getHttpClient(true).request<withdrawWalletAddressData>('withdraw/wallet/address/get', Method.POST, request, ContentType.json)
// }

function balanceAvailable(request: balanceAvailableRequest) {
    return getHttpClient(true).request<balanceAvailableData>('balance/available', Method.POST, request, ContentType.json)
}

function withdrawFee(request: withdrawFeeRequest) {
    return getHttpClient(true).request<balanceAvailableData>('withdraw/fee/show', Method.POST, request, ContentType.json)
}

function withdrawCancel(request: withdrawCancelRequest) {
    return getHttpClient(true).request<balanceAvailableData>('withdraw/req/cancel', Method.POST, request, ContentType.json)
}

function transferSave(request: transferSaveRequest) {
    return getHttpClient(true).request<balanceAvailableData>('transfer/req/save', Method.POST, request, ContentType.json)
}

function subTransferSave(request: transferSaveRequest) {
    return getHttpClient(true).request<balanceAvailableData>('transfer/sub-account/save', Method.POST, request, ContentType.json)
}

function withdrawSave(request: withdrawSaveRequest) {
    return getHttpClient(true).request<balanceAvailableData>('withdraw/req/save', Method.POST, request, ContentType.json)
}

function withdrawBankAccountList(request: withdrawBankAccountRequest) {
    return getHttpClient(true).request<RootObject<withdrawBankAccountData>>('bank/account/list', Method.POST, request, ContentType.json)
}

function cryptoCurrencyList(request: cryptoCurrencyRequest) {
    return getHttpClient(true).request<RootObject<withdrawBankAccountData>>('account/crypto/currency/list', Method.POST, request, ContentType.json)
}

function transferCurrencyList(request: transferCurrencyRequest) {
    return getHttpClient(true).request<RootObject<withdrawBankAccountData>>('transfer/currency/list', Method.POST, request, ContentType.json)
}

function getBillingList(request: FindRequest) {
    const paginationConfig = {
        headers: {
            pageSize: request.pagination.pageSize,
            pageNumber: request.pagination.pageNumber,
            sortby: "time:descending"
        }
    }
    return getHttpClient(true).request<PageRootObject<Billing[]>>('billing/search-by-user', Method.POST, request, ContentType.json, paginationConfig)
}

function exportBillingList(request: FindRequest) {
    return getHttpClient(true).download('/billing/export', Method.POST, request)
}

function getWalletAddressList(request: WithdrawWalletAddressRequest) {
    return getHttpClient(true).request<RootObject<DepositHistoryListData>>('wallet/address/list', Method.POST, request, ContentType.json)
}

function getNewWalletAddressList(request: WithdrawWalletAddressRequest) {
    return getHttpClient(true).request<RootObject<DepositHistoryListData>>('wallet/address/list/byStatus', Method.POST, request, ContentType.json)
}

function addWalletAddress(request: addWalletAddressRequest) {
    return getHttpClient(true).request<RootObject<addWalletAddressData>>('wallet/address/save', Method.POST, request, ContentType.json)
}

function getWalletAddressDetail(request: getWalletAddressRequest) {
    return getHttpClient(true).request<RootObject<addWalletAddressData>>('wallet/address/get-by-id', Method.POST, request, ContentType.json)
}

function walletAddressDelete(request: getWalletAddressRequest) {
    return getHttpClient(true).request<RootObject<addWalletAddressData>>('wallet/address/delete', Method.POST, request, ContentType.json)
}

function getBankAccountList() {
    return getHttpClient(true).request<RootObject<bankAccountListData>>('bank/account/list', Method.GET)
}

function addBankAccount(request: addBankAccountRequest) {
    return getHttpClient(true).request<RootObject<addWalletAddressData>>('bank/account/save', Method.POST, request, ContentType.json)
}

function bankAccountDelete(request: addWalletAddressRequest) {
    return getHttpClient(true).request<RootObject<addWalletAddressData>>('bank/account/delete', Method.POST, request, ContentType.json)
}

function recentTransferList() {
    return getHttpClient(true).request<RootObject<DepositHistoryListData>>('recent/transfer/list', Method.GET)
}

function getAccountTypeByCurrency() {
    return getHttpClient(false).request<RootObject<any>>('currency/list-by-account-type', Method.GET)
}

function getBalanceAvailable(currency: string, accountType: number) {
    const request = { currency: currency, accountType: accountType }
    return getHttpClient(true).request<RootObject<number>>('balance/available-by-account-type', Method.POST, request, ContentType.json)
}
function getCheckAddressValid(request: CheckAddressValidRequest) {
    return getHttpClient(true).request<CheckAddressValidData>('check-address-valid', Method.POST, request, ContentType.json)
}
//swiftCode
function getSwiftChecker(request: SwiftCheckerRequest) {
    return getHttpClient(true).request<SwiftCheckerData>('swift/checker', Method.POST, request, ContentType.json)
}
//充值地址筛查验证
function DepositAddressScreening(request: depositAddressData) {
    return getHttpClient(true).request<RootObject<any>>('screen-address', Method.POST, request, ContentType.json)
}
//bank acount label
function getBankAccountLabelList() {
    return getHttpClient(true).request<RootObject<withdrawBankAccountData>>('bank/account/label/list', Method.GET)
}
//wallet acount label
function getWalletAddressLabelList(request: WalletAddressLabelListRequest) {
    return getHttpClient(true).request<RootObject<withdrawBankAccountData>>('wallet/address/label/list', Method.POST, request, ContentType.json)
}
//校验钱包地址风险性
function checkWalletRisk(request: checkWalletRistRequest) {
    return getHttpClient(true).request<riskObject>('wallet/address/check-wallet-risk', Method.POST, request, ContentType.json)
}
//校验钱包地址
function checkWalletOwner(request: checkWalletOwnerRequest) {
    return getHttpClient(true).request<RootObject<any>>('whitelistedWalletAddress/checkWalletOwner', Method.POST, request, ContentType.json)
}
//小数点校验
function maxDecimalPlaces(request: maxDecimalPlacesRequest) {
    return getHttpClient(true).request<RootObject<any>>('withdraw/get/currency/max-decimal-places', Method.POST, request, ContentType.json)
}
function subBlanceChange(participantCode: string, callback: (data: any) => void) {
    WS.subscribe("/account/balance", { participantCode: participantCode }, callback)
}

function unsubBlanceChange(account: string) {
    WS.unsubscribe("/account/balance", { account: account })
}

//
function apiBankAccount(request:any) {
    return getHttpClient(true).request('deposit/bank/account/get', Method.POST, request, ContentType.json)
}

export default {
    getOverview, getDepositRequiredStatus, getRecentDepositList, getDepositCurrencyList,
    getDepositCurrencyNetworkList, getWalletAddress, getBalanceList, getDepositHistoryList,
    getWithdrawHistoryList, getTransferHistoryList, getWithdrawStatus, getRecentWithdrawList,
    balanceAvailable, withdrawFee, withdrawCancel, getBillingList,
    transferSave, subTransferSave, withdrawSave, withdrawBankAccountList, cryptoCurrencyList, transferCurrencyList,
    exportDepositHistoryList, exportWithdrawHistoryList, exportTransferHistoryList, exportBillingList,
    getWalletAddressList, addWalletAddress, getWalletAddressDetail, walletAddressDelete, getBankAccountList,
    addBankAccount, bankAccountDelete, recentTransferList, getAccountTypeByCurrency, getBalanceAvailable, getCheckAddressValid,
    getSwiftChecker, DepositAddressScreening, getBankAccountLabelList, getWalletAddressLabelList, subBlanceChange, unsubBlanceChange,checkWalletRisk,checkWalletOwner,
    maxDecimalPlaces,getNewWalletAddressList, apiBankAccount

}
