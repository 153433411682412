import { RequestParams, Method, ContentType } from './type';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import DuplicateRequest from './duplicate';
import qs from 'qs';
import { getToken, setToken } from "./auth"
export * from './type';

export interface HttpClientConfig extends AxiosRequestConfig {
    defaultParams?: RequestParams
    //是否开启打印
    log?: boolean
    //是否开启检查快速点击事件
    checkQuickClick?: boolean
    //click interval (点击间隔时间)
    clickInterval?: number
}

class HttpClient {
    public defaultConfig: HttpClientConfig
    public httpClient: AxiosInstance

    constructor(options: HttpClientConfig = {}) {
        this.httpClient = axios.create(options)
        this.defaultConfig = options
    }

    /**
     * @description: 封装请求类
     * @param {Method} method 请求方式
     * @param {APIPath} path 请求路径
     * @param {RequestParams} params 参数
     * @param {ContentType} contentType http配置
     * @param {RequestOptions} optionsSource
     * @return {*}
     */
    async request<T>(
        path: string = '',
        method: Method = Method.GET,
        params?: RequestParams,
        contentType: ContentType = ContentType.json,
        optionsSource?: HttpClientConfig
    ) {
        const options: HttpClientConfig = Object.assign(
            {},
            this.defaultConfig,
            optionsSource
        )
        const { headers, clickInterval } = options
        if (headers != null) {
            headers['Content-type'] = contentType
        }

        const allParams = Object.assign(
            {},
            this.defaultConfig.defaultParams,
            params
        )

        const requestConfig: HttpClientConfig = {
            url: `${path}`,
            method,
            headers,
        };

        if (
            options.checkQuickClick === true &&
            DuplicateRequest.hashUrlAndParams(
                requestConfig.url ?? '',
                method,
                allParams,
                clickInterval
            )
        ) {
            options.log && console.log('click quick')
            return null
        }

        if (method === Method.GET) {
            requestConfig.params = allParams
        } else {
            if (contentType === ContentType.form) {
                requestConfig.data = qs.stringify(allParams)
            } else {
                requestConfig.data = JSON.stringify(allParams)
            }
        }
        return this.httpClient
            .request(requestConfig)
            .then(res => {
                options.log && console.log(res);
                // 分页属性
                if (res.headers.pagecount && res.headers.pagenumber && res.headers.pagesize && res.headers.total) {
                    res.data.pagination = {
                        pageNumber: res.headers.pagenumber,
                        pageSize: res.headers.pagesize,
                        total: res.headers.total,
                        pageCount: res.headers.pagecount
                    }
                }



                // 刷新token
                if (res.headers.refreshtoken) {
                    //console.log("refresh token: " + res.headers.refreshtoken);
                    setToken(res.headers.refreshtoken)
                }

                const data: string = JSON.stringify(res.data);

                if (res.status >= 200 && res.status < 300) {
                    let o = JSON.parse(data) as T
                    return o
                } else if (res.status == 401) {

                }
                else {
                    return Promise.reject(data)
                }
            })
            .catch(async error => {
                if (error.response.status == 401) {
                    if (error.response.data.code == '100100003') {
                        console.log('token invaid: ' + path)
                        window.location.href = '/login'
                    }
                    if (error.response.data.code == '100100004') {
                        // let o = JSON.parse(error.response.data) as T
                        // return o
                    }
                }
                return Promise.reject(error)
            })
    }

    async download(
        path: string = "",
        method: Method = Method.GET,
        params?: RequestParams
    ) {
        const options: HttpClientConfig = this.defaultConfig;
        const { headers, clickInterval } = options;
        if (headers != null) {
            headers["Content-type"] = ContentType.json;
        }

        const allParams = Object.assign(
            {},
            this.defaultConfig.defaultParams,
            params
        );

        const requestConfig: HttpClientConfig = {
            url: `${path}`,
            method,
            headers,
        };

        if (method === Method.GET) {
            requestConfig.params = allParams;
        } else {
            requestConfig.data = JSON.stringify(allParams);
        }
        requestConfig.responseType = "blob";

        return this.httpClient
            .request(requestConfig)
            .then((res) => {
                options.log && console.log(res);
                const fileName = res.headers['content-disposition'].replace(/\w+;filename=(.*)/, '$1')
                let aLink = document.createElement('a')
                let url = window.URL.createObjectURL(res.data)
                aLink.href = url
                aLink.download = decodeURI(fileName)
                aLink.style.display = 'none'
                document.body.appendChild(aLink)
                aLink.click()
                document.removeChild(aLink)
                window.URL.revokeObjectURL(url)
            })
            .catch(async (error) => {
                console.log(error, 'errrrrrrrrrrrrr')
                if (error.response && error.response.status == 401) {
                    console.log("token invaid: " + path);
                    window.location.href = "/login";
                }
                return Promise.reject(error);
            });

    }

    async getDownloadUrl(
        path: string = "",
        method: Method = Method.GET,
        params?: RequestParams
    ) {
        const options: HttpClientConfig = this.defaultConfig;
        const { headers, clickInterval } = options;
        if (headers != null) {
            headers["Content-type"] = ContentType.json;
        }

        const allParams = Object.assign(
            {},
            this.defaultConfig.defaultParams,
            params
        );

        const requestConfig: HttpClientConfig = {
            url: `${path}`,
            method,
            headers,
        };

        if (method === Method.GET) {
            requestConfig.params = allParams;
        } else {
            requestConfig.data = JSON.stringify(allParams);
        }
        requestConfig.responseType = "blob";

        return this.httpClient
            .request(requestConfig)
            .then((res) => {

                options.log && console.log(res);
                const fileName = res.headers['content-disposition'].replace(/\w+;filename=(.*)/, '$1')
                let aLink = document.createElement('a')
                let url = window.URL.createObjectURL(res.data)
                if(requestConfig.params.type && requestConfig.params.type == 'pdf'){
                    const binaryData = []
                    binaryData.push(res.data)
                    let pdfUrl = window.URL.createObjectURL(new Blob(binaryData, { type: "application/pdf" }))
                    return pdfUrl
                }
                return url
            })
            .catch(async (error) => {

                if (error.response.status == 401) {
                    console.log("token invaid: " + path);
                    window.location.href = "/login";
                }
                return Promise.reject(error);
            });
    }
}

export function getHttpClient(hasToken: Boolean = true, baseurl: string = 'api') {
    const token = getToken()
    const config: HttpClientConfig = {
        baseURL: baseurl,
        headers: {
            authorization: hasToken ? `Bearer ${token}` : ''
        }
    }
    return new HttpClient(config)
}