import { RequestParams, ContentType, Method, getHttpClient } from '@/http'
import { CurrencyItem } from '@/model/currencyObject'
import { RootObject, PageRootObject } from '@/model/rootObject'
import { QuoteRangeRequest, QuoteRequest, QuoteRange, Quote, OrderRequest, SerachOrderListRequest, OrderItem,CryptoListRequest } from '@/model/otcObject'
import { Orderbook } from '@/model/orderObject'

function getCryptoList(request: CryptoListRequest) {
    return getHttpClient(true).request<RootObject<CurrencyItem[]>>('otc/crypto/list', Method.POST, request, ContentType.json)
}

function getQuoteRange(request: QuoteRangeRequest) {
    return getHttpClient(true).request<RootObject<QuoteRange>>('otc/quote/range', Method.POST, request, ContentType.json)
}

function getQuote(request: QuoteRequest) {
    return getHttpClient(true).request<RootObject<Quote>>('otc/quote', Method.POST, request, ContentType.json)
}

function placeOrder(request: OrderRequest) {
    return getHttpClient(true).request<RootObject<string>>('otc/place', Method.POST, request, ContentType.json)
}

function getRecentOrderList() {
    const paginationConfig = {
        headers: {
            pageNumber: 1,
            pageSize: 10
        }
    }
    const request = {}
    return getHttpClient(true).request<RootObject<OrderItem[]>>('otc/recent-order/get', Method.POST, request, ContentType.json, paginationConfig)
}

function getOrderList(request: SerachOrderListRequest) {
    const paginationConfig = {
        headers: {
            pageSize: request.pagination.pageSize,
            pageNumber: request.pagination.pageNumber
        }
    }

    return getHttpClient(true).request<PageRootObject<OrderItem[]>>('otc/recent-order/get', Method.POST, request, ContentType.json, paginationConfig)
}


function getOrderbookList() {
    return getHttpClient(true).request<RootObject<Orderbook[]>>('otc/orderbook/list', Method.GET, {}, ContentType.json)
}

export default { getCryptoList, getQuoteRange, getQuote, placeOrder, getRecentOrderList, getOrderList, getOrderbookList }