import { createI18n } from 'vue-i18n'
import zh from './zh'
import en from './en'

const i18n = createI18n({
    // 如果本地有语言标识就采用本地，没有就根据浏览器语言默认标识显示语言
    locale: localStorage.getItem('locale') || 'en',
    messages: {
        en,
        zh
    }
});

const changeLanguage = (lang:string) => {
    const language = lang || localStorage.getItem('locale')
    if (language == "en") {
        localStorage.setItem('locale', 'zh');
        i18n.global.locale = 'zh';
        return 'zh'
    } else {
        localStorage.setItem('locale', 'en');
        i18n.global.locale = 'en';
        return 'en'
    }

}

export { i18n, changeLanguage };